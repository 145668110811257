import useStore from "../Store/Store";
import { updateRepoDataInCodeCanvasIndexedDB } from "../utils/CodeCanvasIndexedDB";
import { logger } from "../utils/Logger";
import { v4 as uuidv4 } from "uuid";
import { extractTextFromHTML } from "../utils/MiscUtils";
import { IApplyCellsStyle } from "../Components/SourceDoc/TabViews/SimulationsTab/SimulationsTab";
import { checkDiagramNodeIdMatch } from "../Components/SourceDoc/TabViews/SimulationsTab/SimulationUtils";
import { discardRecordedActions, saveRecordedActions } from "./UndoManager";
/**
 * handles creating links  based on destination object of
 * merge of data
 *
 * destionation: key of the receiving object to which the
 * metadata of a cell/repoDataObject will be stored after the link
 */
export async function linkFileToNodeHandler(destination: string) {
  const state = useStore.getState();

  // multi guard
  if (state.multiSelect) {
    const dialogMessage = `You cannot link file to multiple cells! 
      Please select a single cell before linking!`;
    state.setDialog("WARNING_DIALOG", {
      message: dialogMessage,
    });
    return;
  }

  // metadata to be copied to repo asset
  else if (destination === state.currentPath) {
    state.setRepoObjectAttribute(
      state.currentPath,
      "wiki",
      state.repoData[state.selectedCell].wiki
    );
  }
  // only assign new name if cellName was previously empty
  else if (
    destination === state.selectedCell &&
    !state.repoData[state.selectedCell].cellName
  ) {
    useStore.getState().postToDrawio({
      action: "NEW_CELL_NAME",
      data: {
        cellId: state.selectedCell,
        newName: state.repoData[state.currentPath].fileName,
      },
    });
  }
  let newRepoObjectAttributes = {
    cellId: state.selectedCell,
    cellName: state.repoData[state.selectedCell].cellName,
  };
  state.setRepoObjectAttributes(state.currentPath, newRepoObjectAttributes);
  // // after a link, the previous cellId linked object will be deleted
  state.deleteRepoObject(state.selectedCell);
  state.setNewCellToPath(state.selectedCell, state.currentPath);
  await updateRepoDataInCodeCanvasIndexedDB();
}

/**
 * handles delete operation from CC
 */
export async function deleteCellsHandler(removedCells: Array<string>) {
  removedCells.forEach((cellId) => {
    const state = useStore.getState();
    let path = cellId;
    if (cellId in state.cellToPath) {
      path = state.cellToPath[cellId];
      state.deleteCellToPath(cellId);
    }
    if (path in state.repoData) {
      state.deleteRepoObjectAttributes(path, ["cellName", "cellId"]);
      if (state.repoData[path]?.parentPath) {
        state.deleteChild(state.repoData[path]?.parentPath, cellId);
      }
      // TODO: Warn before deletion with wiki
      let curRepoDataObject = state.repoData[path];
      if (
        curRepoDataObject.type !== "blob" &&
        curRepoDataObject.type !== "tree"
      ) {
        let destPath = curRepoDataObject.parentPath;
        // TODO: Warn before deletion with wiki
        if (state.deleteMode === "saveWiki") {
          if (destPath && curRepoDataObject?.wiki) {
            let newWikiEntry = `<b>Cell:{L${curRepoDataObject?.startLine}-${curRepoDataObject?.endLine}} wiki:</b>\n${curRepoDataObject?.wiki}`;
            let existingWiki = state.repoData[destPath]?.wiki;
            let mergedWiki = `${
              existingWiki ? existingWiki : "\n"
            }${newWikiEntry}`;
            state.setRepoObjectAttribute(destPath, "wiki", mergedWiki);
          }
        }
        state.deleteRepoObject(path);
      } else {
        if (state.deleteMode === "deleteWiki") {
          state.deleteRepoObjectAttribute(curRepoDataObject.path, "wiki");
        }
      }
    }
  });
  const state = useStore.getState();
  state.setSelectedCell("");
  state.setCurrentPath(state.currentRepo);
  updateRepoDataInCodeCanvasIndexedDB();
}

/**
 * handles add operation from CC
 */
export async function addCellsHandler(newCellIds: Array<any>) {
  const state = useStore.getState();
  newCellIds.forEach((newCell) => {
    let { newCellId, newCellName, parentId, children } = newCell;
    newCellName = extractTextFromHTML(newCellName);

    // skip if cellId already added - protection for duplicate add
    // messages from CC
    if (newCellId in state.cellToPath || newCellId in state.repoData) {
      return;
    }

    // protecting against double message when selected cell
    // is already the current path
    if (state.currentPath !== newCellId) {
      if (
        state.currentPath !== state.currentRepo && // not root
        !state.selectedCell &&
        newCellId
      ) {
        let newRepoObjectAttributes = {
          cellId: newCellId,
          cellName: state.repoData[state.currentPath].fileName,
          visible: true,
        };
        state.setRepoObjectAttributes(
          state.currentPath,
          newRepoObjectAttributes
        );
        state.setNewCellToPath(newCellId, state.currentPath);
      } else if (
        state.repoData[state.selectedCell]?.parentPath === state.currentPath
      ) {
        if (newCellName) {
          let newRepoObjectAttributes = {
            cellId: newCellId,
            cellName: newCellName,
          };
          state.setRepoObjectAttributes(
            state.currentPath,
            newRepoObjectAttributes
          );
        } else {
          state.setRepoObjectAttribute(state.currentPath, "cellId", newCellId);
        }
        state.setNewCellToPath(newCellId, state.currentPath);
      }
      // new node with no links to be added to repoData
      else {
        let newRepoObject = {
          path: newCellId,
          cellId: newCellId,
          type: "cell",
          visible: useStore.getState().simulationsState.isEditingSimulations
            ? false
            : true, // If editing simulations, cell is hidden by defaul outside simulation
        };
        if (parentId) newRepoObject["parentCellId"] = parentId;
        if (newCellName) newRepoObject["cellName"] = newCellName;
        state.setNewRepoObject(newCellId, newRepoObject);
        state.setCurrentPath(newCellId, true);
      }

      children?.forEach((childId) => {
        let newRepoObject = {
          path: childId,
          cellId: childId,
          type: "cell",
          parentCellId: newCellId,
          visible: true,
        };
        state.setNewRepoObject(childId, newRepoObject);
      });
    }
  });
  updateRepoDataInCodeCanvasIndexedDB();
}

/**
 * handles newly selectedCell from DrawIO
 *
 * newCellId: cellId of newly selected Cell
 * multiSelect: boolean to handle case where multiple cells are selected
 */
export function newSelectedCellHandler(newCellIds: Array<string>) {
  useStore.getState().setFlag("isHandlingSelectionChange", true);
  const state = useStore.getState();
  if (state.pausePathChangeToDrawio) {
    state.setPausePathChangeToDrawio(false);
    discardRecordedActions("*");
  }
  state.setLoadingNotification("");

  if (!newCellIds || !newCellIds.length) {
    state.setSearchResults(null);
    state.setCurrentPath(state.currentRepo);
    state.setSelectedCell("");
    useStore.getState().setFlag("isHandlingSelectionChange", false);
    return;
  }

  logger.debug.info(`newSelectedCellHandler: ${newCellIds}`);

  let multiSelect = newCellIds?.length > 1;
  let newCellId = "";
  if (!multiSelect) newCellId = newCellIds[0];
  // protection from infinite loops with drawio or if multi select
  if (newCellId !== state.selectedCell || multiSelect) {
    state.setMultiSelect(multiSelect);

    if (multiSelect) {
      // - setting selectedCell to multi but value not ready anywhere
      // - only setting to this invalid value to avoid any bugs by retaining
      //   the selectedCell even when multiple are selected

      // want to highlight file cell and children cell so need to check if all
      // new cellIds are related to the same file. Then we dont adjust currentPath
      // and keep the file selected which initiated all the cells getting selected
      // in drawio
      let firstParentPath = "";
      if (newCellIds[0] in state.cellToPath) {
        firstParentPath = state.cellToPath[newCellIds[0]];
      } else {
        firstParentPath = state.repoData[newCellIds[0]]?.parentPath;
      }
      if (
        firstParentPath &&
        newCellIds.every(
          (id) =>
            state.repoData[id]?.parentPath === firstParentPath ||
            state.repoData[state.cellToPath[id]]?.path === firstParentPath
        )
      ) {
        state.setSearchResults(null);
        state.setSelectedCell(state.repoData[firstParentPath]?.cellId);
        state.setCurrentPath(firstParentPath, true);
        useStore.getState().setFlag("isHandlingSelectionChange", false);
        return;
      } else {
        state.setSearchResults(null);
        state.setSelectedCell("multi");
        state.setCurrentPath("multi", true);
      }
    } else {
      state.setSelectedCell(newCellId);

      if (!newCellId) {
        state.setCurrentPath(state.currentRepo, false, true);
      }
      // file already linked to file/folder
      if (newCellId in state.cellToPath) {
        let path = state.cellToPath[newCellId];
        if (state.repoData[path].type === "tree") {
          state.setShowCellToFolderParentPath(true);
        }
        state.setSearchResults(null);
        state.setCurrentPath(path, false, true);
      }

      // has exiting data but not linked to file/folder
      else if (newCellId in state.repoData) {
        state.setSearchResults(null);
        state.setCurrentPath(state.repoData[newCellId].path, false, true);
      }
    }
  }
  useStore.getState().setFlag("isHandlingSelectionChange", false);
}

/**
 * handles newly selectedCell from DrawIO
 *
 * @param silentChange - flag to determine whether or not to notify drawio
 * of path change
 *  - used to avoid race conditions and loops during edge condition
 * such as multiple cell addition in a single update
 *
 * @param isDrawioEventOrigin - flag to determine whether or not the event orginated from drawio
 *
 */
export async function currentPathChangeHandler(
  silentChange: boolean,
  isDrawioEventOrigin: boolean = false
) {
  const state = useStore.getState();
  let currentRepoData = state.repoData[state.currentPath];
  let cellId = currentRepoData?.cellId;
  // want to set selectedCell to null/cellId if not linking with
  // the current path change. otherwise, cell will be set to selected
  // after the new cell is added to the graph/linked
  if (!silentChange && !state.multiSelect) {
    state.setSelectedCell(cellId);
  }

  let clearSelection = false;

  // if a file/folder is selected without cell, must
  // send a deselect cells flag to codecanvas
  //
  // if file/folder does have cell, then must highlight
  // as well as select the cell
  if (
    currentRepoData?.type === "blob" ||
    currentRepoData?.type === "tree" ||
    currentRepoData?.parentPath
  ) {
    if (!cellId && !silentChange) {
      clearSelection = true;
    }
  }

  if (
    currentRepoData?.startLine &&
    currentRepoData?.endLine &&
    !state.searchResults
  ) {
    // TODO: talk to Nasser about this
    state.selectedTab === 0 && state.setSelectedTab(1);
  } else if (
    !(currentRepoData?.type === "blob") &&
    !currentRepoData?.startLine &&
    !currentRepoData?.endLine &&
    !currentRepoData?.children &&
    !currentRepoData?.wiki &&
    state.selectedTab !== 3
  ) {
    state.setSelectedTab(0);
  }

  // only send this message to change selections
  // if not linkking the current file/cell
  if (!silentChange) {
    const setFlag = useStore.getState().setFlag;
    setFlag("isChangingPath", true);
    useStore
      .getState()
      .postToDrawioWaitForResponse({
        action: "CURRENT_PATH_CHANGE",
        data: {
          selectId: cellId,
          clearSelection: clearSelection,
          isDrawioEventOrigin: isDrawioEventOrigin,
        },
      })
      .finally(() => setFlag("isChangingPath", false));
  }
}

/**
 * handle label changes
 */
export async function labelChangeHandler(newLabel: string, cellId: string) {
  let destinationPath = useStore.getState().cellToPath[cellId] || cellId;
  useStore
    .getState()
    .setRepoObjectAttribute(
      destinationPath,
      "cellName",
      extractTextFromHTML(newLabel)
    );
  updateRepoDataInCodeCanvasIndexedDB();
}

/**
 * handle label changes
 */
export async function newCellToDrawioHandler(newCellName?: string) {
  const state = useStore.getState();
  await state.postToDrawioWaitForResponse({
    action: "NEW_LINKED_CELL",
    data: { newCellName: newCellName },
  });
  await updateRepoDataInCodeCanvasIndexedDB();
}

export async function newChildCodeCellHandler(
  startLine,
  endLine,
  connectToExistingCell: boolean = false
) {
  const state = useStore.getState();
  let uuidSuffix = uuidv4();
  let childKey = "";

  let parentRepoData = state.repoData[state.currentPath];
  if (parentRepoData.type !== "blob") {
    parentRepoData = state.repoData[parentRepoData.parentPath];
  }
  let parentFileName = parentRepoData?.fileName;
  let newCellName = "";
  if (startLine === endLine) {
    newCellName = `${parentFileName} {L${startLine}}`;
  } else {
    newCellName = `${parentFileName} {L${startLine}:L${endLine}}`;
  }
  let parentCellId = parentRepoData?.cellId;
  let parentPath = parentRepoData?.path;

  if (!connectToExistingCell) {
    childKey = state.currentPath + "-" + uuidSuffix;
    const visible = parentRepoData?.visible === false ? false : true;
    let newRepoObject = {
      cellName: newCellName,
      cellId: childKey,
      fileName: parentFileName,
      parentPath: parentPath,
      parentCellId: parentCellId,
      path: childKey,
      startLine: startLine,
      endLine: endLine,
      visible: visible,
    };

    state.setNewRepoObject(childKey, newRepoObject);
  } else {
    childKey = state.selectedCell;
    let destCell = state.repoData[childKey];
    const visible = destCell?.visible === false ? false : true;
    let codeLinkedCell = {
      ...destCell,
      cellName: destCell.cellName || newCellName,
      fileName: parentFileName,
      parentPath: parentPath,
      parentCellId: parentCellId,
      path: childKey,
      startLine: startLine,
      endLine: endLine,
      visible: visible,
    };

    state.setRepoObjectEntry(childKey, codeLinkedCell);

    if (!destCell.cellName) {
      state.setRepoObjectAttribute(childKey, "cellName", newCellName);
      useStore.getState().postToDrawio({
        action: "NEW_CELL_NAME",
        data: {
          cellId: childKey,
          newName: newCellName,
        },
      });
    }
  }

  if (!parentRepoData?.children) {
    state.setRepoObjectAttribute(parentPath, "children", [childKey]);
  } else {
    state.setRepoObjectAttribute(parentPath, "children", [
      ...parentRepoData?.children,
      childKey,
    ]);
  }

  if (!connectToExistingCell) {
    state.setCurrentPath(childKey);
    state.postToDrawioWaitForResponse({
      action: "NEW_LINKED_CELL",
      data: {
        newCellName: newCellName,
        parentCellId: parentCellId,
        cellId: childKey,
      },
    });
  } else {
    state.setCurrentPath(state.selectedCell);
  }
  state.setPausePathChangeToDrawio(false);
  state.setLoadingNotification("");
  await updateRepoDataInCodeCanvasIndexedDB();
  state.setSelectedText("");
  await saveRecordedActions("ADD_CODE_NODE");
}

// cellIdentifier: cellId || path
export async function unlinkCell(
  cellIdentifier: string,
  wikiDest: string,
  enterEditMode: boolean = false
) {
  let state = useStore.getState();
  if (enterEditMode) {
    state.setPausePathChangeToDrawio(true);
  }
  let pathToUnlink = state.cellToPath[cellIdentifier] || cellIdentifier;
  let cellToUnlink = state.repoData[pathToUnlink];

  if (cellToUnlink.parentPath) {
    state.deleteChild(cellToUnlink.parentPath, cellToUnlink.cellId);
    state.deleteRepoObjectAttributes(pathToUnlink, [
      "parentCellId",
      "fileName",
      "endLine",
      "startLine",
      "parentPath",
    ]);
    if (wikiDest === "file") {
      state.deleteRepoObjectAttribute(pathToUnlink, "wiki");
      let newWikiEntry = `<b>Cell:{L${cellToUnlink?.startLine}-${cellToUnlink?.endLine}} wiki:</b>\n${cellToUnlink?.wiki}`;
      let existingWiki = state.repoData[cellToUnlink.parentPath]?.wiki;
      let mergedWiki = `${existingWiki ? existingWiki : "\n"}${newWikiEntry}`;
      state.setRepoObjectAttribute(cellToUnlink.parentPath, "wiki", mergedWiki);
    }
    state.setCurrentPath(cellToUnlink.parentPath);
    await state.setSelectedTab(1);
  } else {
    state.deleteCellToPath(cellToUnlink.cellId);
    state.deleteRepoObjectAttributes(pathToUnlink, ["cellName", "cellId"]);
    state.setNewRepoObject(cellToUnlink.cellId, {
      path: cellToUnlink.cellId,
      cellId: cellToUnlink.cellId,
      cellName: cellToUnlink.cellName,
      visible: cellToUnlink?.visible === false ? false : true,
    });
    if (wikiDest === "cell") {
      state.deleteRepoObjectAttribute(pathToUnlink, "wiki");
      state.setRepoObjectAttribute(
        cellToUnlink.cellId,
        "wiki",
        cellToUnlink.wiki
      );
    }
    state.setCurrentPath(cellToUnlink.cellId);
  }

  await updateRepoDataInCodeCanvasIndexedDB();
  await saveRecordedActions("UNLINK");
}

export async function toggleSingleCellVisibility(diagramNodeId, visible) {
  var state = useStore.getState();
  var cellToToggle =
    state.repoData[diagramNodeId] ||
    state.repoData[state.cellToPath[diagramNodeId]];
  if (checkDiagramNodeIdMatch(cellToToggle)) {
    return;
  }
  var actionData = {} as IApplyCellsStyle;
  // When the component mounts, set the temporary styles on the diagram node
  if (!cellToToggle || cellToToggle?.visible === false) {
    if (visible) {
      actionData = {
        cellIds: [diagramNodeId],
        // mxGraph styles
        style: {
          visible: true,
        },
        options: {
          temporaryStyleChange: true,
        },
      };

      useStore
        .getState()
        .postToDrawioWaitForResponse({
          action: "CHANGE_CELLS_STYLE",
          data: actionData,
        })
        .catch((error) => {
          logger.error(error);
          logger.error(`Could not apply temporary styles to hidden nodes!}`);
          useStore
            .getState()
            .setErrorNotification(
              `Could not apply temporary styles to hidden nodes!`
            );
        });
    } else {
      if (!useStore.getState()?.simulationsState?.isEditingSimulations) {
        return useStore
          .getState()
          .postToDrawioWaitForResponse({
            action: "CHANGE_CELLS_STYLE",
            data: {
              cellIds: [diagramNodeId],
              style: {
                visible: visible,
              },
              options: {
                temporaryStyleChange: true,
              },
            },
          })
          .catch((error) => {
            logger.error("Error hiding cells: ", error);
          });
      }
    }
  }
}

export async function setCellTags(
  currentRepoData: Object,
  newRepoData: Object
) {
  let cellIdsToTagActions = {};
  // handle special case of entire repo object entry deletion
  Object.entries(currentRepoData).forEach(([key, value]) => {
    if (!newRepoData[key]) {
      cellIdsToTagActions[key] = {
        remove: ["linkedTag", "wikiTag"],
      };
    }
  });

  // post to drawio
  if (Object.keys(cellIdsToTagActions).length) {
    useStore
      .getState()
      .postToDrawioWaitForResponse({
        action: "SET_CELLS_TAGS",
        data: cellIdsToTagActions,
      })
      .then((resp) => {
        if (resp.status === "SUCCESS") return;
      })
      .catch((err) => {
        logger.error.log("Error setting cell tags", err);
      });
  }

  cellIdsToTagActions = {};
  // diff each object as normal
  Object.entries(newRepoData).forEach(([key, value]) => {
    // new repoData entry
    if (!(key in currentRepoData)) {
      let tagsToAdd = [];
      if (value?.cellId) {
        // checking fileName to determine if cell is linked
        if (value?.fileName) {
          tagsToAdd.push("linkedTag");
        }
        if (value?.wiki) {
          tagsToAdd.push("wikiTag");
        }
      }
      // might need to handle children case
      // existing repoData entry
      if (tagsToAdd.length > 0) {
        cellIdsToTagActions[value.cellId] = {
          add: tagsToAdd,
        };
      }
    } else {
      let tagsToAdd = [];
      // link changed
      if (value?.fileName && value?.cellId) {
        tagsToAdd.push("linkedTag");
      } else {
        // Unlinked cell (cellId was removed)
        if (currentRepoData[key].cellId) {
          cellIdsToTagActions[currentRepoData[key].cellId] = {
            remove: ["linkedTag"],
          };
        }
      }

      // wiki changed
      if (value?.wiki && value?.cellId) {
        tagsToAdd.push("wikiTag");
      } else {
        // Unlinked cell (cellId was removed)
        if (currentRepoData[key].cellId) {
          if (
            cellIdsToTagActions[currentRepoData[key].cellId]?.remove?.length > 0
          ) {
            cellIdsToTagActions[currentRepoData[key].cellId].remove.push(
              "wikiTag"
            );
          } else {
            cellIdsToTagActions[currentRepoData[key].cellId] = {
              remove: ["wikiTag"],
            };
          }
        }
      }

      if (value?.cellId && tagsToAdd.length > 0) {
        // if newRepoData cellId is the same as currentRepoData cellId, then we need to add the tags
        if (
          value.cellId === currentRepoData[key].cellId &&
          cellIdsToTagActions[value.cellId]
        ) {
          cellIdsToTagActions[value.cellId].add = tagsToAdd;
        } else {
          cellIdsToTagActions[value.cellId] = {
            add: tagsToAdd,
          };
        }
      }
    }
  });

  if (Object.keys(cellIdsToTagActions).length) {
    useStore
      .getState()
      .postToDrawioWaitForResponse({
        action: "SET_CELLS_TAGS",
        data: cellIdsToTagActions,
      })
      .then((resp) => {
        if (resp.status === "SUCCESS") return;
      })
      .catch((err) => {
        logger.error.log("Error setting cell tags", err);
      });
  }
}
