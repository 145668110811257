import useStore from "../Store/Store";
import { logger } from "./Logger";

// A small helper to parse edges from the raw XML,
// returning an array of { id, source, target } objects
function parseEdgesFromXml(xmlString: string) {
  const edgeRegex = /<mxCell[^>]*edge="1"[^>]*>/g;
  const idRegex = /id="([^"]*)"/;
  const sourceRegex = /source="([^"]*)"/;
  const targetRegex = /target="([^"]*)"/;

  const edges = xmlString.match(edgeRegex) || [];
  return edges.map((edgeXml) => {
    const idMatch = edgeXml.match(idRegex);
    const sourceMatch = edgeXml.match(sourceRegex);
    const targetMatch = edgeXml.match(targetRegex);

    return {
      id: idMatch ? idMatch[1] : null,
      source: sourceMatch ? sourceMatch[1] : null,
      target: targetMatch ? targetMatch[1] : null,
    };
  });
}

/**
 * A DFS that *only* continues in one direction ('incoming' or 'outgoing').
 */
function directionalDFS(
  currentCellId: string,
  edges: { id: string; source: string; target: string }[],
  visited: Set<string>,
  direction: "incoming" | "outgoing",
  level: number,
  maxLevels: number,
  result: string[]
) {
  // Stop if we reach max depth
  if (level > maxLevels) return;

  // Record that we visited this cell in this direction
  const visitedKey = `${currentCellId}:${direction}`;
  if (!visited.has(visitedKey)) {
    visited.add(visitedKey);
    result.push(currentCellId);
  }

  // Filter relevant edges depending on the direction
  const relevantEdges =
    direction === "incoming"
      ? edges.filter((e) => e.target === currentCellId)
      : edges.filter((e) => e.source === currentCellId);

  for (const edge of relevantEdges) {
    // Mark the edge itself as visited if not already
    if (edge.id && !visited.has(edge.id)) {
      visited.add(edge.id);
      result.push(edge.id);
    }

    // Then figure out the "next cell" to follow
    const nextCellId = direction === "incoming" ? edge.source : edge.target;
    if (!nextCellId) continue;

    // If we haven't visited that next cell in this same direction, recurse
    const nextCellKey = `${nextCellId}:${direction}`;
    if (!visited.has(nextCellKey)) {
      directionalDFS(
        nextCellId,
        edges,
        visited,
        direction,
        level + 1,
        maxLevels,
        result
      );
    }
  }
}

/**
 * Returns a single array (within an array) of all cells+edges
 * found when performing directional DFS from the selectedCell.
 *
 * Handles both:
 * - Node selection: same as your current logic
 * - Edge selection: we perform a two-sided DFS (incoming from source, outgoing from target).
 */
export function getConnectedCellIds(maxLevels = Infinity): string[][] {
  logger.info("getConnectedCellIds with maxLevels:", maxLevels);

  // 1. Grab the currently selected cell (could be a node ID or an edge ID) & the raw XML
  const cellId = useStore.getState().selectedCell;
  const xmlString = useStore.getState().diagramData.drawioXML;
  const edges = parseEdgesFromXml(xmlString);

  if (!cellId) {
    // If there is no cell selected, return empty
    return [[]];
  }

  // Check if the selected cell is an actual edge
  const selectedEdge = edges.find((edge) => edge.id === cellId);
  // We'll keep track of visited items in a Set so we don’t repeat edges/cells
  const visited = new Set<string>();
  // Our final "flat" result
  const result: string[] = [];

  if (selectedEdge) {
    //
    // ============== EDGE SELECTED ==============
    //
    logger.info("Selected an EDGE with id:", cellId);

    // 1. Add the edge itself
    visited.add(selectedEdge.id);
    result.push(selectedEdge.id);

    // 2. If a valid source exists, run DFS "incoming" from it
    if (
      selectedEdge.source &&
      !visited.has(`${selectedEdge.source}:incoming`)
    ) {
      directionalDFS(
        selectedEdge.source,
        edges,
        visited,
        "incoming",
        1,
        maxLevels,
        result
      );
    }

    // 3. If a valid target exists, run DFS "outgoing" from it
    if (
      selectedEdge.target &&
      !visited.has(`${selectedEdge.target}:outgoing`)
    ) {
      directionalDFS(
        selectedEdge.target,
        edges,
        visited,
        "outgoing",
        1,
        maxLevels,
        result
      );
    }
  } else {
    //
    // ============== NODE SELECTED ==============
    //
    logger.debug.info("Selected a NODE with id:", cellId);

    // 1. Identify edges connected to this node (incoming or outgoing)
    const connectedEdges = edges.filter(
      (edge) => edge.source === cellId || edge.target === cellId
    );

    // 2. Add the selected node itself to the result
    result.push(cellId);
    // Mark both 'incoming' and 'outgoing' so we don't DFS from it twice
    visited.add(`${cellId}:incoming`);
    visited.add(`${cellId}:outgoing`);

    // 3. For each connected edge, figure out which direction we should go
    connectedEdges.forEach((edge) => {
      // Mark the edge itself if not visited
      if (edge.id && !visited.has(edge.id)) {
        visited.add(edge.id);
        result.push(edge.id);
      }

      // If the selected node is the target, then we are looking at an "incoming" edge
      if (edge.target === cellId && edge.source) {
        if (!visited.has(`${edge.source}:incoming`)) {
          directionalDFS(
            edge.source,
            edges,
            visited,
            "incoming",
            1,
            maxLevels,
            result
          );
        }
      }
      // Otherwise, if the selected node is the source, it's an "outgoing" edge
      else if (edge.source === cellId && edge.target) {
        if (!visited.has(`${edge.target}:outgoing`)) {
          directionalDFS(
            edge.target,
            edges,
            visited,
            "outgoing",
            1,
            maxLevels,
            result
          );
        }
      }
    });
  }

  // Finally, return as an array of arrays, per your original usage.
  return [result];
}
