import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";

export const DisclaimerContainer = styled.div`
  margin-left: auto;
  text-align: right;
  color: grey;
  font-size: 0.7em;
  max-width: 80%;
  margin-top: 2px;
  margin-right: 10px;
`;

interface DisclaimerContentProps {
  includedFiles?: { name: string; content: string }[];
  excludedFiles?: string[];
}

export const DisclaimerContent: React.FC<DisclaimerContentProps> = ({
  includedFiles,
  excludedFiles,
}) => {
  return (
    <DisclaimerContainer>
      <Typography
        variant="subtitle2"
        style={{ whiteSpace: "pre-wrap" }}
        fontSize={12}
        id="included-files"
      >
        <strong>Included files:</strong>{" "}
        {includedFiles?.length
          ? includedFiles
              .map((file) => {
                const parts = file.name.split("/");
                return parts[parts.length - 1];
              })
              .join(", ")
          : "None"}
      </Typography>

      {excludedFiles?.length ? (
        <Typography
          variant="subtitle2"
          style={{ whiteSpace: "pre-wrap" }}
          fontSize={12}
          id="excluded-files"
        >
          <strong>Excluded files (due to token limits):</strong>{" "}
          {excludedFiles
            .map((exFile) => {
              const parts = exFile.split("/");
              return parts[parts.length - 1];
            })
            .join(", ")}
        </Typography>
      ) : null}
    </DisclaimerContainer>
  );
};
