import useStore from "../../../../Store/Store";
import { logger } from "../../../../utils/Logger";
import { getFileContent } from "../../SourceDocHandlers";

const MAX_TOKENS = 100000;

// TODO: ensure clean merge with brian's version
export function getConnectedCellIds(): string[][] {
  logger.debug.info("getConnectedCellIds");
  const cellId = useStore.getState().selectedCell;
  const xmlString = useStore.getState().diagramData.drawioXML;
  const edgeRegex = /<mxCell[^>]*edge="1"[^>]*>/g;
  const sourceRegex = /source="([^"]*)"/;
  const targetRegex = /target="([^"]*)"/;
  const edges = xmlString.match(edgeRegex) || [];
  const visited = new Set<string>();
  const result: string[][] = [];

  // BFS to find connected cells by level
  const bfs = (startCellId: string) => {
    const queue: [string, number][] = [[startCellId, 0]];
    visited.add(startCellId);

    while (queue.length > 0) {
      const [currentCellId, level] = queue.shift()!;
      if (!result[level]) {
        result[level] = [];
      }
      result[level].push(currentCellId);

      for (const edge of edges) {
        const sourceMatch = edge.match(sourceRegex);
        const targetMatch = edge.match(targetRegex);
        const source = sourceMatch ? sourceMatch[1] : null;
        const target = targetMatch ? targetMatch[1] : null;

        if (source === currentCellId && target && !visited.has(target)) {
          visited.add(target);
          queue.push([target, level + 1]);
        }

        if (target === currentCellId && source && !visited.has(source)) {
          visited.add(source);
          queue.push([source, level + 1]);
        }
      }
    }
  };

  bfs(cellId);
  console.debug("Connected Cells by level distance: " + result);
  return result;
}

function estimateTokens(text) {
  const averageCharsPerToken = 4; // Adjust this based on your data
  return Math.ceil(text.length / averageCharsPerToken);
}

export async function getFilesFromConnectedCells(connectedCells, promptLength) {
  console.debug("getFilesFromConnectedCells");
  const cellToPath = useStore.getState().cellToPath;
  const repoData = useStore.getState().repoData;

  // Arrays to return
  let includedFiles = [];
  let excludedFiles = [];
  let notBlobCells = [];

  let visited = new Set();
  let tokenCount = 0;

  for (let i = 0; i < connectedCells.length; i++) {
    for (let j = 0; j < connectedCells[i].length; j++) {
      let cId = connectedCells[i][j];
      console.debug(cId);

      // Resolve path from cell ID
      let path = cellToPath[cId] || cId;
      path = repoData[path]?.parentPath || path;
      let rd = repoData[path];

      if (!rd) {
        // If rd is undefined (path not found in repoData), treat it as "not blob"
        // or handle it as you see fit. For now, let's push it to notBlobCells.
        notBlobCells.push({ cellId: cId, path });
        continue;
      }

      if (!visited.has(path)) {
        visited.add(path);

        // Check if it's a blob
        if (rd.type !== "blob") {
          // It's not a file blob, so push to 'notBlobCells'
          notBlobCells.push({ cellId: cId, path });
          continue;
        }

        // It's a blob; get file content (may need an API call)
        const fileContent =
          rd.fileContent ||
          (rd.type === "blob" && (await getFileContent(path))) ||
          "";

        // Estimate tokens
        const estimatedTokens = estimateTokens(fileContent);

        // Check if adding this file would exceed the max tokens
        if (tokenCount + estimatedTokens > MAX_TOKENS - promptLength) {
          console.warn(
            `Token limit exceeded. Cannot include file at path: ${path}`
          );
          excludedFiles.push(path);
          continue; // Move on to next file
        }

        // Otherwise, include this file
        tokenCount += estimatedTokens;
        console.debug(tokenCount);
        includedFiles.push({
          name: path,
          role: "user",
          content: `CellName:${rd.cellName} File:${path}\n${fileContent}`,
        });
      }
    }
  }

  console.debug("Included files:", includedFiles);
  console.debug("Excluded files due to token limit:", excludedFiles);
  console.debug("Cells not of type blob:", notBlobCells);

  // Return the three arrays
  return {
    includedFiles,
    excludedFiles,
    notBlobCells,
  };
}
