import "./SimulationsTab.css";
import { createContext, useCallback, useEffect, useState } from "react";
import SimulationsControls from "./SimulationsControls";
import useStore from "../../../../Store/Store";
import { Dropdown } from "../../../Header/Dropdown/Dropdown";
import { SimulationsBody } from "./SimulationsBody";
import { theme } from "../../../../Themes/Themes";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { SimulationsEditNameDelete } from "./SimulationsEditNameDelete";
import { logger } from "../../../../utils/Logger";
import { toggleCellsVisibility } from "./SimulationUtils";
import {
  discardRecordedActions,
  saveRecordedActions,
} from "../../../../Store/UndoManager";
import { resetTemporaryStyles } from "../../../DiagramHighlight/DiagramHighlight";

interface ISimulationsTab {
  selectedTab: number;
  isMaxSD: boolean;
  setIsMaxSD: Function;
}

export const INITIAL_MODIFIED_SIMULATION_STATE: Simulation = {
  name: "",
  simSteps: [],
  simulationNodesAndEdges: {},
  description: "",
};
export interface IApplyCellsStyle {
  cellIds?: string[];
  style?: object;
  options?: {
    removeTemporaryStyles?: boolean;
    lockDiagram?: boolean;
    temporaryStyleChange?: boolean;
    permanentStyleChange?: boolean;
  };
}
type ISimulationsContext = {
  isRunning: boolean;
  isNewSimulation: boolean;
  isMaxSD: boolean;
  currentStep: string;
  prev: string;
  selectedTab: number;
  isSettingSimulationName: boolean;
  simulationNameError: string;
  currentUnsavedSimulationObject: Simulation;
  wiki: string;
  setWiki: React.Dispatch<React.SetStateAction<string>>;
  setIsSettingSimulationName: React.Dispatch<React.SetStateAction<boolean>>;
  setSimulationNameError: React.Dispatch<React.SetStateAction<string>>;
  setIsRunning: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNewSimulation: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentStep: (step: any) => void;
  setPrev: React.Dispatch<React.SetStateAction<string>>;
  setIsMaxSD: Function;
  saveModifiedSimulation: Function;
  simulationDescription: string;
  setSimulationDescription: React.Dispatch<React.SetStateAction<string>>;
};

export const SimulationsContext = createContext<
  ISimulationsContext | undefined
>(undefined);

export const SimulationsTab: React.FC<ISimulationsTab> = (
  props: ISimulationsTab
) => {
  const {
    simulations,
    setSimulations,
    currentUnsavedSimulationObject,
    setCurrentUnsavedSimulationObject,
    repoData,
    currentPath,
    setWikiContent,
    isEditingSimulations,
    currentStep,
    simulationsState,
    setSimulationsState,
    selectedSimulationKey,
    selectedCell,
    setSelectedSimulationKey,
    setSelectedCell,
  } = useStore((state) => ({
    simulations: state.simulationsState.simulations,
    currentUnsavedSimulationObject: state.currentUnsavedSimulationObject,
    repoData: state.repoData,
    currentPath: state.currentPath,
    setSimulations: state.setSimulations,
    setCurrentUnsavedSimulationObject: state.setCurrentUnsavedSimulationObject,
    setWikiContent: state.setWikiContent,
    isEditingSimulations: state.simulationsState.isEditingSimulations,
    setIsEditingSimulations: state.setIsEditingSimulations,
    currentStep: state.simulationsState.currentStep,
    simulationsState: state.simulationsState,
    setSimulationsState: state.setSimulationsState,
    selectedCell: state.selectedCell,
    selectedSimulationKey: state.simulationsState.selectedSimulationKey,
    setSelectedSimulationKey: state.setSelectedSimulationKey,
    setSelectedCell: state.setSelectedCell,
  }));

  const { isMaxSD, setIsMaxSD, selectedTab } = props;
  const [prev, setPrev] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [isNewSimulation, setIsNewSimulation] = useState(false);
  const [isSettingSimulationName, setIsSettingSimulationName] = useState(false);
  const [simulationNameError, setSimulationNameError] = useState("");
  const [simulationsOptions, setSimulationsOptions] = useState([]);

  const [simulationDescription, setSimulationDescription] = useState(
    currentUnsavedSimulationObject.description || ""
  );
  const [wiki, setWiki] = useState(repoData[currentPath]?.wiki || "");

  const saveModifiedSimulation = async () => {
    let newSimulationsWithCurrentOneRemoved = Object.fromEntries(
      Object.entries(simulations).filter(
        ([key]) => key !== selectedSimulationKey
      )
    );
    newSimulationsWithCurrentOneRemoved[currentUnsavedSimulationObject.name] =
      currentUnsavedSimulationObject;
    await resetTemporaryStyles();
    await setSimulations(newSimulationsWithCurrentOneRemoved);
    await setSelectedSimulationKey(currentUnsavedSimulationObject.name);
    await setWikiContent(currentPath, wiki);
    await saveRecordedActions("SIMULATION_MODIFIED");
  };

  function renderSimulations(simulations) {
    logger.debug.info("renderSimulations", simulations);
    var simList = [];

    if (simulations) {
      Object.keys(simulations).forEach((s) =>
        simList.push(
          <option key={s} value={s} data-testid={`simulationEntry-${s}`}>
            {s}
          </option>
        )
      );
    }

    return simList;
  }

  const selectSimulation = async (event) => {
    const selectedValue = event.target.value;
    await setSelectedSimulationKey("");
    await resetTemporaryStyles();
    if (isSettingSimulationName) {
      setIsSettingSimulationName(false);
      setIsNewSimulation(false);
    }
    setCurrentStep("");
    // we have to store event.target.value in a variable because resetTemporaryStyles triggers a re-render which resets the dropdown value
    await setSelectedSimulationKey(selectedValue);
  };

  const handleCreateSimulation = async () => {
    await toggleCellsVisibility(false);
    await setSelectedSimulationKey("");
    setIsSettingSimulationName(true);
    setIsNewSimulation(true);
    await setCurrentUnsavedSimulationObject(INITIAL_MODIFIED_SIMULATION_STATE);
    await discardRecordedActions("CURRENT_UNSAVED_SIMULATION_OBJECT");
  };

  // Changes the currentStep simulation object to the selected simulation
  useEffect(() => {
    if (simulations && selectedSimulationKey in simulations) {
      setCurrentUnsavedSimulationObject(simulations[selectedSimulationKey]);
    }
  }, [selectedSimulationKey, simulations, setCurrentUnsavedSimulationObject]);

  // UseEffect to set current step when is editing is enabled to the first step if any
  useEffect(() => {
    if (
      !currentStep &&
      currentUnsavedSimulationObject.simSteps.length > 0 &&
      currentUnsavedSimulationObject?.name === selectedSimulationKey
    ) {
      setCurrentStep(currentUnsavedSimulationObject.simSteps[0].simStepId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, currentUnsavedSimulationObject, selectedSimulationKey]);

  // Sets "currentStep" in Zustand's state.simulationsState.currentStep
  const setCurrentStep = useCallback(
    (step) => {
      setSimulationsState({ ...simulationsState, currentStep: step });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSimulationsState, simulationsState, selectedSimulationKey]
  );

  // UseEffect to set current step when selectedCell is changed (when cell selected changes)
  // If there are multiple simsteps have the same selectedCell, it will set the currentStep to first one after the currentStep
  useEffect(() => {
    if (
      currentUnsavedSimulationObject.simSteps.length > 0 &&
      currentStep &&
      selectedSimulationKey
    ) {
      const diagramNodeId = currentUnsavedSimulationObject.simSteps.find(
        (step) => step.simStepId === currentStep
      )?.diagramNodeId;

      if (selectedCell === diagramNodeId) {
        return;
      }

      let currentStepIndex = currentUnsavedSimulationObject.simSteps.findIndex(
        (step) => step.simStepId === currentStep
      );
      let nextStepIndex = currentUnsavedSimulationObject.simSteps.findIndex(
        (step) =>
          step.diagramNodeId === selectedCell &&
          currentUnsavedSimulationObject.simSteps.indexOf(step) >
            currentStepIndex
      );
      let newIdx = null;
      if (nextStepIndex !== -1) {
        newIdx = nextStepIndex;
      } else {
        // loop backwards from currentStep to 0 and set the selectedCell to the closest one from the currentStep
        for (let i = currentStepIndex; i >= 0; i--) {
          if (
            currentUnsavedSimulationObject.simSteps[i].diagramNodeId ===
            selectedCell
          ) {
            newIdx = i;
            break;
          }
        }
      }
      if (newIdx === null) {
        return;
      }
      setCurrentStep(currentUnsavedSimulationObject.simSteps[newIdx].simStepId);
      const simStepsList = document.getElementsByClassName("SimStepsList")[0];
      // them scroll the one that's found into view
      simStepsList.scrollTo({
        top: 36 * newIdx - simStepsList.clientHeight / 2,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUnsavedSimulationObject, selectedCell, selectedSimulationKey]);
  // When simulations change, set the simulation options state by calling renderSimulations
  useEffect(() => {
    setSimulationsOptions(renderSimulations(simulations));
  }, [simulations]);

  return (
    <>
      <SimulationsContext.Provider
        value={{
          isRunning,
          isNewSimulation,
          isMaxSD,
          setIsMaxSD,
          currentStep,
          prev,
          selectedTab,
          isSettingSimulationName,
          simulationNameError,
          currentUnsavedSimulationObject,
          wiki,
          simulationDescription,
          setWiki,
          setIsSettingSimulationName,
          setSimulationNameError,
          setPrev,
          setCurrentStep,
          setIsRunning,
          setIsNewSimulation,
          saveModifiedSimulation,
          setSimulationDescription,
        }}
      >
        <div className="simulationsContainer">
          {isEditingSimulations ? (
            <SimulationsEditNameDelete />
          ) : (
            <div className="simulationsEditNameDelete">
              <Dropdown
                data-testid="simulationsDropdown"
                className="simulationsDropdown"
                options={simulationsOptions}
                onChange={selectSimulation}
                placeholder={"Select Simulation to Start"}
                value={selectedSimulationKey}
              />
              <div className="circularButton">
                <AddRoundedIcon
                  style={{
                    width: "80%",
                    height: "80%",
                    fill: theme.custom.lighterPink,
                  }}
                  onClick={handleCreateSimulation}
                />
              </div>
            </div>
          )}

          <SimulationsControls />
          <SimulationsBody />
        </div>
      </SimulationsContext.Provider>
    </>
  );
};

export default SimulationsTab;
