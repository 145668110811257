import "./SourceDoc.css";

// react
import { useEffect, useState } from "react";

// third-party components
import { Tabs, Tab } from "@mui/material";
import { Resizable } from "re-resizable";

import { Path } from "./Path/Path";
import { SearchBar } from "../SearchBar/SearchBar";

import { SourceDocControlButtons } from "./SourceDocControlButtons";
import SourceDocButton from "./SourceDocButton";

import { RepoTab } from "./TabViews/RepoTab/RepoTab";
import { CodeTab } from "./TabViews/CodeTab/CodeTab";
import { DocsTab } from "./TabViews/DocsTab/DocsTab";
import { SimulationsTab } from "./TabViews/SimulationsTab/SimulationsTab";
import { AITab } from "./TabViews/AITab/AITab";

import useStore from "../../Store/Store";

import { theme } from "../../Themes/Themes";
import { logger } from "../../utils/Logger";
import { toggleCellsVisibility } from "./TabViews/SimulationsTab/SimulationUtils";
import { TabPanel } from "./TabPanel";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    "overflow-y": "auto",
    "max-height": "85%",
  };
}

function onResizeStart() {
  // create a transparent overlay to prevent user from clicking on other elements
  const overlay = document.createElement("div");
  overlay.style.position = "absolute";
  overlay.style.top = "0";
  overlay.style.left = "0";
  overlay.style.width = "103.1vw";
  overlay.style.height = "103.1vh";
  overlay.style.zIndex = "1000";
  overlay.style.backgroundColor = "transparent";
  document.body.appendChild(overlay);
  // remove overlay when resizing is done
  const removeOverlay = () => {
    document.body.removeChild(overlay);
    window.removeEventListener("mouseup", removeOverlay);
  };
  window.addEventListener("mouseup", removeOverlay);
}

export const SourceDoc: React.FC = () => {
  const [isOpenSD, setIsOpenSD] = useState(true);
  const [isMaxSD, setIsMaxSD] = useState(false);
  const [width, setWidth] = useState("38vw");
  const [height, setHeight] = useState("80vh");
  const {
    selectedTab,
    setSelectedTab,
    repoData,
    currentPath,
    selectedSimulationKey,
  } = useStore((state) => ({
    selectedTab: state.selectedTab,
    setSelectedTab: state.setSelectedTab,
    repoData: state.repoData,
    currentPath: state.currentPath,
    selectedSimulationKey: state.simulationsState.selectedSimulationKey,
  }));

  // Tabs: handlers for state of tabs
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Maximize/Minimize SourceDoc
  useEffect(() => {
    if (isMaxSD) {
      setHeight("103.1vh");
      setWidth("48vw");
    } else {
      setHeight("85vh");
      setWidth("35vw");
    }
  }, [isMaxSD]);
  // useEffect if simulationKey Changes post to drawio to reset the simulation styles
  useEffect(() => {
    const simulations = useStore.getState().simulationsState.simulations;
    const isEditingSimulations =
      useStore.getState().simulationsState.isEditingSimulations;
    if (
      isOpenSD &&
      selectedSimulationKey &&
      isEditingSimulations === "SimSteps"
    ) {
      useStore.getState().setCellsSetToCorrespondingVisiblityState(true);
      return;
    }
    toggleCellsVisibility(false).then((response) => {
      if (response?.status === "SUCCESS") {
        if (isOpenSD && selectedSimulationKey) {
          // post to drawio to take a backup of the original styling
          // and the set temporary styles
          const simulation =
            useStore.getState().simulationsState.simulations[
              selectedSimulationKey
            ];
          if (!simulation) {
            logger.error("No simulation found");
            useStore.getState().setErrorNotification("No simulation found");
            return;
          }
          useStore.getState().setCellsSetToCorrespondingVisiblityState(true);
        } else if (Object.keys(simulations).length > 0) {
          //post to drawio to restore the original styling and set elements whose visibility is false to false
          useStore
            .getState()
            .postToDrawioWaitForResponse({
              action: "CHANGE_CELLS_STYLE",
              data: {
                options: {
                  removeTemporaryStyles: true,
                },
              },
            })
            .then((response) => {
              if (response?.status === "SUCCESS") {
                useStore
                  .getState()
                  .setCellsSetToCorrespondingVisiblityState(true);
                useStore.getState().setShowHideIrrelevantCellsToggle(false);
              } else {
                logger.error(response);
                throw new Error("Error restoring original styling");
              }
            })
            .catch((error) => logger.error(error));
        }
      }
    });
  }, [selectedSimulationKey, isOpenSD]);

  if (isOpenSD) {
    return (
      <div className="openSD">
        <Resizable
          size={{ width, height }}
          className="sourceDocContainer"
          style={{
            position: "absolute",
            top: isMaxSD ? "0" : "10vh",
            right: isMaxSD ? "0" : "1.5vw",
            borderRadius: isMaxSD ? "0" : "15px",
          }}
          onResizeStart={onResizeStart}
          onResizeStop={(e, direction, ref, d) => {
            setWidth(width + d.width);
            setHeight(height + d.height);
          }}
          minHeight={"85vh"}
        >
          <SourceDocControlButtons
            setHeight={setHeight}
            setIsMaxSD={setIsMaxSD}
            isMaxSD={isMaxSD}
            setIsOpenSD={setIsOpenSD}
            setWidth={setWidth}
          />
          <div style={{ width: "100%" }}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
              value={selectedTab}
              onChange={handleTabChange}
              aria-labelledby={`source-doc-tabs`}
              centered
            >
              <Tab className="RepoTab" label="Repo" {...a11yProps(0)} />
              <Tab
                className="CodeTab"
                label="Code"
                disabled={
                  !repoData[currentPath]?.fileContent &&
                  !repoData[currentPath]?.startLine &&
                  !repoData[currentPath]?.endLine
                }
                {...a11yProps(1)}
              />
              <Tab className="DocsTab" label="Docs" {...a11yProps(2)} />
              <Tab
                className="SimulationTab"
                label="Simulations"
                {...a11yProps(3)}
              />
              <Tab className="AITab" label="GPT" {...a11yProps(4)} />
            </Tabs>
          </div>
          <div
            className="SDContentContainer"
            style={
              isMaxSD
                ? { height: "calc(100% - 80px" }
                : { height: "calc(100% - 120px" }
            }
          >
            <div className="SDContent">
              <SearchBar />
              <Path />
              <RepoTab isMaxSD={isMaxSD} />
              <TabPanel value={selectedTab} index={1}>
                <CodeTab isMaxSD={isMaxSD} />
              </TabPanel>
              <DocsTab isMaxSD={isMaxSD} />

              <AITab isMaxSD={isMaxSD} />
              <SimulationsTab
                selectedTab={selectedTab}
                isMaxSD={isMaxSD}
                setIsMaxSD={setIsMaxSD}
              />
            </div>
          </div>
        </Resizable>
      </div>
    );
  } else {
    return <SourceDocButton setIsOpenSD={setIsOpenSD} />;
  }
};
