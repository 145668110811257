import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import RestorePageRoundedIcon from "@mui/icons-material/RestorePageRounded";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import GitHubIcon from "@mui/icons-material/GitHub";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import useStore from "../../../Store/Store";
import { saveFileToGithub } from "../../../Api/ServerInterface";
import { theme } from "../../../Themes/Themes";
import {
  openDirectory,
  saveFile,
  saveFileAs,
} from "../../../utils/LocalFileController/LocalFileController";
import { loadReferenceDiagramFile } from "../../../Api/LoadDiagram";
import {
  buildRepoDataSnapshot,
  getRepoDataFromGitHub,
} from "../../../utils/RepoDataAction";
import {
  deleteRepoDataInCodeCanvasIndexedDB,
  getDirectoryHandleFromCodeCanvasIndexedDB,
} from "../../../utils/CodeCanvasIndexedDB";
import { toggleCellsVisibility } from "../../SourceDoc/TabViews/SimulationsTab/SimulationUtils";
import { INITIAL_MODIFIED_SIMULATION_STATE } from "../../SourceDoc/TabViews/SimulationsTab/SimulationsTab";
import { IRecentlyOpenedEntry } from "../../LandingPage/RecentFilesDropdown";
import { discardRecordedActions } from "../../../Store/UndoManager";
import workflowFile from "../../../utils/CodeCanvasWorkflowFile.txt";
import { login } from "../../../utils/AuthUtils";
import { resetTemporaryStyles } from "../../DiagramHighlight/DiagramHighlight";
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 15,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.custom.lightGrey,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function FileMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    currentRepo,
    currentBranch,
    isLoggedIn,
    fileXML,
    fileName,
    currentRepoMetadata,
    sessionMode,
    recentlyOpenedList,
    setDialog,
    postToDrawioWaitForResponse,
    setSuccessNotification,
    setErrorNotification,
    setLoadingNotification,
    setDoLoadDiagram,
    setSelectedCell,
  } = useStore((state) => ({
    currentRepo: state.currentRepo,
    currentBranch: state.currentBranch,
    isLoggedIn: state.session.isLoggedIn,
    fileXML: state.session.local.diagramFile.fileXML,
    fileName: state.session.local.diagramFile.fileName,
    currentRepoMetadata: state.currentRepoMetadata,
    sessionMode: state.session.mode,
    recentlyOpenedList: state.recentlyOpenedList,
    setDialog: state.setDialog,
    postToDrawioWaitForResponse: state.postToDrawioWaitForResponse,
    setSuccessNotification: state.setSuccessNotification,
    setErrorNotification: state.setErrorNotification,
    setLoadingNotification: state.setLoadingNotification,
    setDoLoadDiagram: state.setDoLoadDiagram,
    setSelectedCell: state.setSelectedCell,
  }));

  const confirmLogin = useCallback(async () => {
    if (
      window.confirm(
        "CodeCanvas requires you to install our Github app on your repo with read/write access in order for us to save directly to your repo.\n\nIf you're not comfortable with that, you can always save the diagram to your machine by clicking 'Save'"
      )
    ) {
      sessionStorage.setItem(
        "redirectUrl",
        window.location.href.replace(
          "session=unauthenticatedGithub&",
          "session=github&"
        )
      );
      login();
    }
  }, []);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    if (!isLoggedIn) {
      confirmLogin();
    }
    handleClose();
  };

  const handlePushToGithub = () => {
    if (!isLoggedIn) {
      confirmLogin();
    }
    if (!currentBranch) {
      setDialog("CHOOSE_REPO_AND_BRANCH");
      handleClose();
      return;
    }
    onSave();
    handleClose();
  };

  const resetToOrigin = async () => {
    useStore.getState().setIsEditingSimulations("");
    await useStore
      .getState()
      .setCurrentUnsavedSimulationObject(INITIAL_MODIFIED_SIMULATION_STATE);

    await discardRecordedActions("CURRENT_UNSAVED_SIMULATION_OBJECT");

    const key = `${currentRepo}-${currentBranch}.drawio`;

    deleteRepoDataInCodeCanvasIndexedDB();
    postToDrawioWaitForResponse({
      action: "BROWSER_STORAGE_OPERATION",
      data: { key: key, operation: "DELETE" },
    })
      .then(() => {
        getRepoDataFromGitHub();
      })
      .catch(function (error) {
        console.error(error);
        setErrorNotification("Could not reset to origin, please try again.");
      });
  };

  const handleResetToOrigin = () => {
    if (!currentBranch) {
      setDialog("CHOOSE_REPO_AND_BRANCH");
      handleClose();
      return;
    }

    const dialogMessage = `Resetting to remote branch will erase any uncommitted local changes on
   the diagram!`;
    setDialog("WARNING_DIALOG", {
      message: dialogMessage,
      button1Function: resetToOrigin,
    });
    handleClose();
  };

  const discardChanges = async () => {
    try {
      useStore.getState().setIsEditingSimulations("");
      await useStore
        .getState()
        .setCurrentUnsavedSimulationObject(INITIAL_MODIFIED_SIMULATION_STATE);
      await discardRecordedActions("CURRENT_UNSAVED_SIMULATION_OBJECT");

      const key = `${currentRepo}-${currentBranch}-local.drawio`;
      await deleteRepoDataInCodeCanvasIndexedDB();
      await postToDrawioWaitForResponse({
        action: "BROWSER_STORAGE_OPERATION",
        data: { key: key, operation: "DELETE" },
      });
      const recentlyOpenedEntry: IRecentlyOpenedEntry =
        await getDirectoryHandleFromCodeCanvasIndexedDB(currentRepo);

      const directoryHandle =
        recentlyOpenedEntry?.local as FileSystemDirectoryHandle;
      await openDirectory(true, directoryHandle);
      setSelectedCell("");
    } catch (error) {
      console.error(error);
      setErrorNotification(
        "Could discard changes, please clear cache from the options menu and try again."
      );
    }
  };

  // Discards unsaved changes and reloads file
  // This function is similar to reset to origin but for local
  const handleDiscardChanges = () => {
    if (!currentBranch) {
      setDialog("CHOOSE_REPO_AND_BRANCH");
      handleClose();
      return;
    }

    const dialogMessage = `Are you sure you want to discard all unsaved changes?`;
    setDialog("WARNING_DIALOG", {
      message: dialogMessage,
      button1Function: discardChanges,
    });
    handleClose();
  };

  const handleSaveAs = () => {
    saveFileAs();
    handleClose();
  };

  const handleSave = () => {
    saveFile();
    handleClose();
  };

  const handleOpenFolder = () => {
    openDirectory();
    handleClose();
  };

  const handleOpenRecent = () => {
    useStore.getState().setShowLandingComponent(true);
    handleClose();
  };

  const handleOpenRepoFromURL = () => {
    useStore.getState().setShowLandingComponent(true);
    handleClose();
  };

  const onSave = useCallback(async () => {
    if (!isLoggedIn) {
      handleLogin();
      return;
    }
    await resetTemporaryStyles();
    useStore.getState().setShowHideIrrelevantCellsToggle(false);
    const diagramData = useStore.getState().diagramData;
    const saveResponse = await saveFileToGithub(
      currentRepo,
      currentBranch,
      {
        ...diagramData,
        repoData: await buildRepoDataSnapshot(),
        simulations: useStore.getState().simulationsState.simulations,
        cellToPath: useStore.getState().cellToPath,
      },
      currentRepoMetadata.owner,
      `${currentRepo}.CodeCanvas`
    );
    if (saveResponse?.status === 200 || saveResponse?.status === 201) {
      // If it's a new diagram file, trigger the get repo data flow so that we retrieve the new
      // diagram file from github and make it appear as a file in SD repo folder
      if (useStore.getState().isNewDiagramFileCreated) {
        // store the github workflow yml file in the github repo
        const workflowFileContent = await fetch(workflowFile).then((r) =>
          r.text()
        );
        await saveFileToGithub(
          currentRepo,
          currentBranch,
          workflowFileContent,
          currentRepoMetadata.owner,
          ".github/workflows/codecanvas.yml"
        );
        useStore.getState().setIsNewDiagramFileCreated(false);
        getRepoDataFromGitHub();
      } else {
        loadReferenceDiagramFile();
      }
      useStore
        .getState()
        .setSuccessNotification(
          `Successfully saved diagram to ${currentRepo}/${currentBranch}`
        );
    } else {
      useStore
        .getState()
        .setErrorNotification(
          `Error saving diagram to ${currentRepo}/${currentBranch}`
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, currentRepo, currentBranch]);

  useEffect(() => {
    if (fileXML) {
      postToDrawioWaitForResponse({
        action: "UPDATE_DIAGRAM",
        options: { loadNewDiagram: true },
        data: { xml: fileXML },
      })
        .then((response) => {
          if (response.status === "SUCCESS") {
            setSuccessNotification(`Successfully loaded ${fileName}`);
            setLoadingNotification("");
            setDoLoadDiagram(false);

            // hide hidden cells
            toggleCellsVisibility(false);
          } else {
            throw new Error(JSON.stringify(response));
          }
        })
        .catch((error) => {
          setErrorNotification(`Error loading ${fileName}`);
          setLoadingNotification("");
          setDoLoadDiagram(false);
          console.error("Error loading file", error);
        });
    }
  }, [
    fileName,
    fileXML,
    postToDrawioWaitForResponse,
    setErrorNotification,
    setSuccessNotification,
    setLoadingNotification,
    setDoLoadDiagram,
  ]);

  return (
    <div style={{ marginRight: "10px" }}>
      <Button
        sx={{
          borderRadius: "30px",
          height: "28px",
        }}
        id="fileMenuButton"
        aria-controls={open ? "fileMenu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={
          <ArrowDropDownRoundedIcon
            sx={{ color: theme.custom.lightestGrey }}
            style={{ transform: "scale(1.8)" }}
          />
        }
      >
        <Typography
          mx={0}
          my={0.8}
          fontSize="13px"
          fontWeight="Bold"
          color={theme.custom.lightestGrey}
          id={"file-button"}
        >
          File
        </Typography>
      </Button>
      <StyledMenu
        id="fileMenu"
        MenuListProps={{
          "aria-labelledby": "fileMenuButton",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {currentBranch &&
          (sessionMode === "github" ||
            sessionMode === "unauthenticatedGithub") && (
            <MenuItem onClick={handleResetToOrigin} disableRipple>
              <RestartAltRoundedIcon />
              Reset to origin/{currentBranch}
            </MenuItem>
          )}
        {currentBranch &&
          (sessionMode === "github" ||
            sessionMode === "unauthenticatedGithub") && (
            <MenuItem onClick={handlePushToGithub} disableRipple>
              <ArrowCircleUpRoundedIcon />
              Push to origin/{currentBranch}
            </MenuItem>
          )}
        {currentBranch &&
          (sessionMode === "github" ||
            sessionMode === "unauthenticatedGithub") && (
            <Divider sx={{ my: 0.5 }} />
          )}
        {sessionMode === "local" && (
          <MenuItem onClick={handleLogin} disableRipple>
            <GitHubIcon />
            Open a remote repository
          </MenuItem>
        )}
        {currentBranch && (
          <MenuItem id={"save-button"} onClick={handleSave} disableRipple>
            <SaveRoundedIcon />
            Save
          </MenuItem>
        )}
        {currentBranch && (
          <MenuItem id={"save-as-button"} onClick={handleSaveAs} disableRipple>
            <SaveRoundedIcon />
            Save As
          </MenuItem>
        )}
        <MenuItem onClick={handleOpenFolder} disableRipple>
          <UploadRoundedIcon />
          Open Folder
        </MenuItem>
        {currentBranch && sessionMode === "local" && (
          <MenuItem onClick={handleDiscardChanges} disableRipple>
            <RestartAltRoundedIcon />
            Discard changes
          </MenuItem>
        )}
        <Divider sx={{ my: 0.5 }} />

        {recentlyOpenedList && recentlyOpenedList.length > 0 && (
          <MenuItem onClick={handleOpenRecent} disableRipple>
            <RestorePageRoundedIcon />
            Open Recent
          </MenuItem>
        )}
        <MenuItem onClick={handleOpenRepoFromURL} disableRipple>
          <LinkRoundedIcon />
          Open Repo from URL
        </MenuItem>
      </StyledMenu>
      <a id="aDownloadFile" href="null" download style={{ display: "none" }}>
        Download File
      </a>
      <input type="file" id="filePicker" style={{ display: "none" }} />
    </div>
  );
}
