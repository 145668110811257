import { useEffect, useRef } from "react";
import { logger } from "../../utils/Logger";
import useStore from "../../Store/Store";
import { theme } from "../../Themes/Themes";
import { getConnectedCellIds } from "../../utils/DiagramUtils";

export const resetTemporaryStyles = async () => {
  try {
    const response = await useStore.getState().postToDrawioWaitForResponse({
      action: "CHANGE_CELLS_STYLE",
      data: {
        options: {
          removeTemporaryStyles: true,
        },
      },
    });
    if (response.status === "SUCCESS") {
      if (!useStore.getState().simulationsState.selectedSimulationKey) {
        useStore.getState().setShowHideIrrelevantCellsToggle(false);
      }
    } else {
      throw new Error(response);
    }
  } catch (error) {
    logger.error("Error resetting temporary styles: ", JSON.stringify(error));
  }
};

export const DiagramHighlight = () => {
  const previousHighlightedCells = useRef([]);

  const { selectedCell, selectedSimulationKey } = useStore((state) => {
    return {
      selectedCell: state.selectedCell,
      selectedSimulationKey: state.simulationsState.selectedSimulationKey,
    };
  });

  useEffect(() => {
    if (selectedSimulationKey) return;
    if (selectedCell) {
      applyHighlightWithConnections(selectedCell);
    } else {
      resetHighlight(previousHighlightedCells.current);
      previousHighlightedCells.current = []; // Clear previous highlights
    }
  }, [selectedCell, selectedSimulationKey]);

  const applyHighlightWithConnections = (cellId) => {
    const connectedCells = getConnectedCellIds(7); // Get connected cells grouped by levels
    const allCellsToHighlight = connectedCells.flat(); // Flatten the levels into a single array

    // Reset styles for previously highlighted cells
    if (previousHighlightedCells.current.length > 0) {
      resetHighlight(previousHighlightedCells.current);
    }

    useStore
      .getState()
      .postToDrawioWaitForResponse({
        action: "CHANGE_CELLS_STYLE",
        data: {
          cellIds: allCellsToHighlight,
          style: {
            stroke: theme.custom.translucentPink,
            strokeWidth: 14,
            visible: true,
            flowAnimation: 1,
          },
          options: {
            temporaryStyleChange: true,
          },
        },
      })
      .then(() => {
        previousHighlightedCells.current = allCellsToHighlight; // Update previously highlighted cells
        useStore.getState().setShowHideIrrelevantCellsToggle(true);
      })
      .catch((error) => {
        logger.error("Error applying highlight to connected cells: ", error);
      });
  };

  const resetHighlight = (cellIds) => {
    useStore
      .getState()
      .postToDrawioWaitForResponse({
        action: "CHANGE_CELLS_STYLE",
        data: {
          cellIds: cellIds,
          options: {
            removeTemporaryStyles: true,
          },
        },
      })
      .then((response) => {
        if (response?.status === "SUCCESS") {
          useStore.getState().setShowHideIrrelevantCellsToggle(false);
        }
      })
      .catch((error) => logger.error("Error resetting highlight: ", error));
  };

  return null; // This component doesn't render anything visible
};
