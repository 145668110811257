// @flow
// Converted automatically using ./tools/themeFromVsCode

import { PrismTheme } from "prism-react-renderer";
export var theme: PrismTheme = {
  plain: {
    color: "#000000",
    backgroundColor: "#f6f8fa",
  },
  styles: [
    {
      types: ["punctuation", "constant", "deleted", "tag"],
      style: {
        color: "rgb(0, 111, 230)",
      },
    },
    {
      types: ["variable", "keyword", "selector"],
      style: {
        color: "rgb(0, 16, 255)",
      },
    },
    {
      types: ["builtin", "operator", "changed", "namespace", "class-name"],
      style: {
        color: "rgb(0, 0, 0)",
      },
    },
    {
      types: ["inserted", "string"],
      style: {
        color: "rgb(199, 75, 75)",
      },
    },
    {
      types: ["char", "number", "attr-name"],
      style: {
        color: "rgb(153, 114, 0)",
      },
    },
    {
      types: ["function"],
      style: {
        color: "rgb(151, 0, 255)",
      },
    },
    {
      types: ["symbol"],
      style: {
        color: "rgb(115, 0, 0)",
      },
    },
    {
      types: ["comment"],
      style: {
        fontStyle: "italic",
      },
    },
  ],
};
