import useStore from "../Store/Store";
import * as Sentry from "@sentry/react";
import { handleServerResponseError } from "./ServerInterface";
import { logger } from "../utils/Logger";

export async function perform(method, resource, data, customReqParams = null) {
  const BASE_URI = process.env.REACT_APP_BACKEND_URL;
  const accessToken = localStorage.getItem("accessToken");
  const setErrorNotification = useStore.getState().setErrorNotification;

  // Set your maximum total wait limit in seconds
  const MAX_TOTAL_WAIT = 70;

  // Prepare the request parameters
  let reqParams;
  if (method === "post" || method === "put") {
    const reqData = {
      ...data,
      isLoggedIn: useStore.getState().session.isLoggedIn,
    };
    reqParams = {
      method,
      credentials: "include",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(reqData),
    };
  } else {
    reqParams = {
      method,
      credentials: "include",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }

  // Merge in any custom request parameters
  if (customReqParams) {
    reqParams = {
      ...reqParams,
      ...customReqParams,
    };
  }

  // Exponential backoff variables
  let attempt = 1;
  let totalWait = 0;
  let warningBool = false;

  while (true) {
    try {
      let res = await fetch(`${BASE_URI}${resource}`, reqParams);
      // If we get a 429, apply exponential backoff
      if (res.status === 429) {
        // Calculate the delay in seconds as 2^(attempt - 1)
        const delay = 2 ** (attempt - 1);

        if (totalWait > 14 && !warningBool) {
          setErrorNotification(
            "Request is taking a long time! Please wait a moment for us to resolve the queue!"
          );
          warningBool = true;
        }

        // Check if adding this delay exceeds our total wait cap
        if (totalWait + delay > MAX_TOTAL_WAIT) {
          throw new Error(
            `Stopping after total wait time of ${totalWait} ms exceeded ${MAX_TOTAL_WAIT} ms.`
          );
        }

        // Wait before retrying
        await new Promise((resolve) => setTimeout(resolve, delay * 1000));
        totalWait += delay;
        attempt++;

        // Retry the request by continuing the loop
        continue;
      }

      // If the response is not OK (and not 429), handle the error
      if (!res.ok) {
        await handleServerResponseError(res);
        return null;
      }

      // Response is OK; parse and return JSON
      const jsonData = await res.json();
      return jsonData;
    } catch (e) {
      logger.error(e);
      Sentry.captureException(e);
      throw e; // If you want to bubble the error up
    }
  }
}
