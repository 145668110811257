import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import useStore from "../../../../Store/Store";
import { IApplyCellsStyle, SimulationsContext } from "./SimulationsTab";
import { useContext, useEffect, useMemo, useState } from "react";
import { theme } from "../../../../Themes/Themes";
import { logger } from "../../../../utils/Logger";
import { Tooltip } from "@mui/material";

interface ISimulationNodeProp {
  simStep: SimStep;
  index: number;
  className?: string;
}

const SimulationsNode: React.FC<ISimulationNodeProp> = (
  props: ISimulationNodeProp
) => {
  const { simStep, className } = props;
  const simStepId = simStep.simStepId;
  const simStepLabel = simStep.simStepLabel;
  const diagramNodeId = simStep.diagramNodeId;
  const nodeOrEdgeClass = simStep.isEdge
    ? "simulationEdge"
    : "simulationNode linked";

  const { currentStep, setCurrentStep, isRunning, setIsRunning } =
    useContext(SimulationsContext);

  const {
    currentUnsavedSimulationObject,
    setCurrentUnsavedSimulationObject,
    isEditingSimulations,
    selectedSimulationKey,
    cellsSetToCorrespondingVisiblityState,
    repoData,
    cellToPath,
    cellsWithUnsavedVisibilityFlag,
  } = useStore((state) => ({
    currentUnsavedSimulationObject: state.currentUnsavedSimulationObject,
    setCurrentUnsavedSimulationObject: state.setCurrentUnsavedSimulationObject,
    isEditingSimulations: state.simulationsState.isEditingSimulations,
    selectedSimulationKey: state.simulationsState.selectedSimulationKey,
    cellsSetToCorrespondingVisiblityState:
      state.simulationsState.cellsSetToCorrespondingVisiblityState,
    repoData: state.repoData,
    cellToPath: state.cellToPath,
    cellsWithUnsavedVisibilityFlag:
      state.simulationsState.cellsWithUnsavedVisibilityFlag,
  }));

  const [displayLabel, setDisplayLabel] = useState(
    simStepLabel ? simStepLabel : simStepId
  );
  const [newStepLabel, setNewStepLabel] = useState(displayLabel);

  const path = useMemo(
    () => cellToPath[diagramNodeId] || diagramNodeId,
    [cellToPath, diagramNodeId]
  );

  const [visibleOutsideSimulation, setVisibleOutsideSimulation] = useState(
    !!repoData[path]?.visible
  );

  const [cellExistsOnDiagram, setCellExistsOnDiagram] = useState(
    path in repoData
  );

  // For highlight styling
  let selectedClassName = simStep.isEdge ? "selectedEdge" : "selectedNode";
  let selected = currentStep === simStepId ? selectedClassName : "";

  // ----------------------------------------
  // Display label logic
  // ----------------------------------------
  useEffect(() => {
    const displayLabel = simStepLabel || simStepId;
    // We don't want to display the simStepId if it's an edge and no custom label
    if (
      simStep.isEdge &&
      (!simStepLabel || simStepLabel === simStepId) &&
      !isEditingSimulations
    ) {
      setDisplayLabel("");
    } else {
      setDisplayLabel(displayLabel);
    }
  }, [simStepLabel, simStep, isEditingSimulations, currentStep, simStepId]);

  // ----------------------------------------
  // Click / mouseDown: sets current step
  // ----------------------------------------
  const simStepMouseDownHandler = () => {
    // hacky: force update if there's only one step
    if (currentUnsavedSimulationObject.simSteps.length === 1) {
      useStore.getState().setCurrentUnsavedSimulationObject({
        ...currentUnsavedSimulationObject,
        simSteps: [{ ...currentUnsavedSimulationObject.simSteps[0] }],
      });
    }

    setCurrentStep(simStepId);
    if (!isRunning) {
      setIsRunning(true);
    }
  };

  // ----------------------------------------
  // Remove simulation step
  // ----------------------------------------
  async function handleRemoveSimulationStep() {
    let simulationNodesAndEdges = {
      ...currentUnsavedSimulationObject.simulationNodesAndEdges,
    };

    if (simStep.diagramNodeId) {
      let simulationDiagramElement = {
        ...simulationNodesAndEdges[simStep.diagramNodeId],
      };
      // check if it has any other simSteps
      if (simulationDiagramElement?.simStepIds?.length === 1) {
        // if not, remove it
        delete simulationNodesAndEdges[simStep.diagramNodeId];
      } else {
        // remove this simStepId from the simStepIds array
        let simStepIds = simulationDiagramElement.simStepIds.filter(
          (id) => id !== simStepId
        );
        simulationDiagramElement.simStepIds = simStepIds;
        simulationNodesAndEdges[simStep.diagramNodeId] =
          simulationDiagramElement;
      }
    }

    let simSteps = currentUnsavedSimulationObject.simSteps;
    let newSimSteps = simSteps.filter((step) => step.simStepId !== simStepId);

    await setCurrentUnsavedSimulationObject({
      ...currentUnsavedSimulationObject,
      simSteps: newSimSteps,
      simulationNodesAndEdges,
    });

    // set current step to first step if current step is deleted
    if (currentStep === simStepId && newSimSteps?.length > 0) {
      setCurrentStep(newSimSteps[0].simStepId);
    }
    logger.debug.info(
      "simulationNodesAndEdges",
      currentUnsavedSimulationObject.simulationNodesAndEdges
    );
  }

  // ----------------------------------------
  // Toggle outside-of-simulation visibility
  // ----------------------------------------
  function handleElementVisibleChange(visible: boolean) {
    const cellsWithUnsavedVis = {
      ...useStore.getState().simulationsState.cellsWithUnsavedVisibilityFlag,
    };

    // If new visiblity flag is same as repoData, remove from unsaved
    if (visible === repoData[path]?.visible) {
      if (path in cellsWithUnsavedVis) {
        delete cellsWithUnsavedVis[path];
      }
    } else {
      // If new visibility is different from repoData, store it as unsaved
      cellsWithUnsavedVis[path] = visible;
    }
    useStore.getState().setCellsWithUnsavedVisibilityFlag(cellsWithUnsavedVis);
  }

  // ----------------------------------------
  // Subscribe to store changes
  // ----------------------------------------
  const subscribe = useStore.subscribe;

  useEffect(() => {
    let prevVisibility: boolean | undefined;

    // We'll read from the `state` param rather than from outer scope variables
    const reflectVisibilityStateChange = async (state: Store) => {
      const element = state.repoData[path];

      // 1) If the cell no longer exists in the diagram
      if (
        state.repoData &&
        Object.keys(state.repoData).length > 0 &&
        !element
      ) {
        setCellExistsOnDiagram(false);

        // Check if we actually need to set `diagramNodeId = null`
        // Only if it's currently not null in the store's own simSteps
        const storeSimSteps = state.currentUnsavedSimulationObject.simSteps;
        const targetSimStep = storeSimSteps.find(
          (s) => s.simStepId === simStepId
        );

        if (targetSimStep && targetSimStep.diagramNodeId !== null) {
          // Construct new simSteps with diagramNodeId = null
          const newSimSteps = storeSimSteps.map((step) => {
            if (step.simStepId === simStepId) {
              return { ...step, diagramNodeId: null };
            }
            return step;
          });

          let simulationNodesAndEdges = {
            ...state.currentUnsavedSimulationObject.simulationNodesAndEdges,
          };
          if (diagramNodeId && diagramNodeId in simulationNodesAndEdges) {
            delete simulationNodesAndEdges[diagramNodeId];
          }

          // Compare the old vs. new object; only set if changed
          const newObject = {
            ...state.currentUnsavedSimulationObject,
            simSteps: newSimSteps,
            simulationNodesAndEdges,
          };

          // If newObject differs from the current store object, set it
          await useStore
            .getState()
            .setCurrentUnsavedSimulationObject(newObject);
        }
        return;
      }

      // 2) Cell does exist: reflect possible visibility changes
      if (path in state.simulationsState.cellsWithUnsavedVisibilityFlag) {
        setVisibleOutsideSimulation(
          state.simulationsState.cellsWithUnsavedVisibilityFlag[path]
        );
      } else if (element && element.visible !== prevVisibility) {
        setVisibleOutsideSimulation(element?.visible);
        prevVisibility = element?.visible;
      }
    };

    const unsubscribe = subscribe(reflectVisibilityStateChange);
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    diagramNodeId,
    subscribe,
    selectedSimulationKey,
    path,
    cellsWithUnsavedVisibilityFlag,
  ]);

  // ----------------------------------------
  // On mount, set temporary styles on diagram node
  // ----------------------------------------
  useEffect(() => {
    if (!cellsSetToCorrespondingVisiblityState) return;

    // When the component mounts, set the temporary styles on the diagram node
    const actionData: IApplyCellsStyle = {
      cellIds: [diagramNodeId],
      style: {
        stroke: theme.custom.translucentPink,
        strokeWidth: 14,
        visible: true,
        flowAnimation: 1,
      },
      options: {
        temporaryStyleChange: true,
      },
    };
    useStore
      .getState()
      .postToDrawioWaitForResponse({
        action: "CHANGE_CELLS_STYLE",
        data: actionData,
      })
      .then((response) => {
        if (response?.status === "SUCCESS") {
          useStore.getState().setShowHideIrrelevantCellsToggle(true);
        } else {
          logger.error(response);
          throw new Error("Error setting temporary styles: ", response);
        }
      })
      .catch((error) => {
        if (!cellExistsOnDiagram) return;
        logger.error(error);
        logger.error(
          `Could not apply temporary styles to node ${diagramNodeId}`
        );
        useStore
          .getState()
          .setErrorNotification(
            `Could not apply temporary styles to node ${diagramNodeId}`
          );
      });
    // when simstep component unmounts (either when deleted, or when we close simulation), remove temporary styles
    return () => {
      useStore
        .getState()
        .postToDrawioWaitForResponse({
          action: "CHANGE_CELLS_STYLE",
          data: {
            cellIds: [diagramNodeId],
            options: {
              removeTemporaryStyles: true,
            },
          },
        })
        .then((response) => {
          if (response?.status === "SUCCESS") {
            // This entire return from useEffect might not be needed when selectedSimulationKey is "". This is because we have a centralized
            // remove temporary styles in the useEffect in SourceDoc.tsx
          } else {
            logger.error(response);
            throw new Error(
              "Error restoring Simstep's temp styles: ",
              response
            );
          }
        })
        .catch((error) => {
          if (!cellExistsOnDiagram) return;
          logger.error(error);
          logger.error(
            `Could not remove temporary styles from node ${diagramNodeId}`
          );
          useStore
            .getState()
            .setErrorNotification(
              `Could not remove temporary styles from node ${diagramNodeId}`
            );
        });
    };
  }, [
    diagramNodeId,
    cellsSetToCorrespondingVisiblityState,
    isEditingSimulations,
    selectedSimulationKey,
    cellExistsOnDiagram,
  ]);

  // ----------------------------------------
  // Debounce label changes
  // ----------------------------------------
  const setStepLabelInCurrentSimulationObject = async () => {
    if (newStepLabel !== simStepLabel) {
      // Build a new object with updated label
      const newObject = {
        ...currentUnsavedSimulationObject,
        simSteps: currentUnsavedSimulationObject.simSteps.map((step) =>
          step.simStepId === simStepId
            ? { ...step, simStepLabel: newStepLabel }
            : step
        ),
      };

      await setCurrentUnsavedSimulationObject(newObject);
    }
  };
  // Triggers once every 300 ms at max and only if newStepLabel is different from simStepLabel
  // allows, the save button to be active or disabled based on whether there are any unsaved changes
  useEffect(() => {
    const timer = setTimeout(setStepLabelInCurrentSimulationObject, 300);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStepLabel]);

  return (
    <div className={`${selected} ${className} ${nodeOrEdgeClass}`}>
      <div
        className="simulationNodeInternalWrapper"
        onMouseDown={simStepMouseDownHandler}
      >
        <div className="simulationNodeInternalContent">
          {simStep.isEdge && (
            <SouthRoundedIcon
              style={{
                height: "30px",
                width: "50px",
                color: theme.custom.darkGrey,
              }}
              fontSize="small"
            />
          )}

          {isEditingSimulations ? (
            <input
              style={{
                outline: "none",
                border: "none",
                color:
                  simStep.isEdge && selected ? theme.custom.pink : "inherit",
              }}
              className="simStepLabelInput simulationNodeInternalText"
              value={newStepLabel}
              placeholder={"Add label"}
              onBlur={setStepLabelInCurrentSimulationObject}
              onChange={(e) => {
                setNewStepLabel(e.target.value);
              }}
            />
          ) : (
            <p className="simulationNodeInternalText">{displayLabel}</p>
          )}
        </div>
      </div>

      {!cellExistsOnDiagram && (
        <Tooltip title="Cell not found in diagram">
          <div
            className="iconWrapper"
            style={{ cursor: "default", color: theme.custom.pink }}
          >
            <ErrorRoundedIcon style={{ width: "100%" }} fontSize="small" />
          </div>
        </Tooltip>
      )}

      {isEditingSimulations && (
        <>
          {cellExistsOnDiagram && (
            <div className="iconWrapper">
              {!visibleOutsideSimulation && (
                <Tooltip title="Cell hidden outside simulation">
                  <VisibilityOffIcon
                    style={{ width: "100%" }}
                    fontSize="small"
                    onClick={() => handleElementVisibleChange(true)}
                  />
                </Tooltip>
              )}
              {visibleOutsideSimulation && (
                <Tooltip title="Cell visible outside simulation. Click to hide">
                  <VisibilityIcon
                    style={{ width: "100%" }}
                    fontSize="small"
                    onClick={() => handleElementVisibleChange(false)}
                  />
                </Tooltip>
              )}
            </div>
          )}

          <div className="iconWrapper">
            <DeleteRoundedIcon
              style={{ width: "100%" }}
              fontSize="small"
              onClick={handleRemoveSimulationStep}
            />
          </div>
          <div
            className="iconWrapper"
            style={{ marginRight: "20px", cursor: "grab" }}
            onMouseDown={simStepMouseDownHandler}
          >
            <DragHandleRoundedIcon fontSize="small" />
          </div>
        </>
      )}
    </div>
  );
};

export default SimulationsNode;
