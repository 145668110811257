import React, { useCallback, useRef, useState } from "react";
import { TabPanel } from "../../TabPanel";
import useStore from "../../../../Store/Store";
import { Button, CircularProgress, TextField } from "@mui/material";
import {
  getConnectedCellIds,
  getFilesFromConnectedCells,
} from "./AITabHandlers";
import { getChatCompletionFromOpenAI } from "../../../../Api/ServerInterface";
import { ChatHistory } from "./ChatHistory"; // <--- import from new file
import "./AITab.css";
import "../RepoTab/RepoTab.css";

interface IAITab {
  isMaxSD: boolean;
}

type ChatMessage = {
  role: "user" | "assistant";
  content: string;
  includedFiles?: { name: string; content: string }[];
  excludedFiles?: string[];
};

export const AITab: React.FC<IAITab> = ({ isMaxSD }) => {
  const { selectedTab, selectedCell, setErrorNotification } = useStore(
    (state) => ({
      selectedTab: state.selectedTab,
      selectedCell: state.selectedCell,
      setErrorNotification: state.setErrorNotification,
    })
  );

  const editorContentRef = useRef("");
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);

  const fetchOpenAI = useCallback(async () => {
    setLoading(true);
    try {
      const editorContent = editorContentRef.current || "No content available";

      // 1. Gather connected cells
      const cellIds = getConnectedCellIds();

      // 2. Fetch files
      const { includedFiles, excludedFiles } = await getFilesFromConnectedCells(
        cellIds,
        editorContent.length
      );

      if (includedFiles.length === 0) {
        setErrorNotification(
          "Please select a cell/path linked to code to ask questions about it!"
        );
        return;
      }

      // 3. Add user message to chat history
      setChatHistory((prev) => [
        ...prev,
        {
          role: "user",
          content: editorContent,
          includedFiles,
          excludedFiles,
        },
      ]);

      // 4. Get AI response
      const chatCompletion = await getChatCompletionFromOpenAI({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content:
              "You are a coding assistant. Provide context before detailed explanations, and include examples.",
          },
          ...includedFiles.map((file) => ({
            role: "system" as const,
            content: `Context file: ${file.name}\n${file.content}`,
          })),
          { role: "user", content: editorContent },
        ],
      });

      const aiResponse =
        chatCompletion.choices[0]?.message?.content || "No response.";

      setChatHistory((prev) => [
        ...prev,
        { role: "assistant", content: aiResponse },
      ]);
    } catch (error) {
      setChatHistory((prev) => [
        ...prev,
        {
          role: "assistant",
          content:
            "Error fetching response. Please try again later. Or let us know in our Discord: https://discord.com/invite/t3ezMyMPqr",
        },
      ]);
    } finally {
      setLoading(false);
    }
  }, [setErrorNotification]);

  return (
    <TabPanel value={selectedTab} index={4}>
      <div
        className="repoContainer"
        style={{
          height: isMaxSD ? "83%" : "87%",
          overflowX: "hidden",
          margin: 0,
          padding: 0,
        }}
      >
        {/* Chat History */}
        <div id="chatContainer" className="chatContainer">
          <ChatHistory chatHistory={chatHistory} />
        </div>

        {/* Input box and button */}
        <div className="stickyTextBoxAI">
          <TextField
            disabled={selectedCell?.length === 0 ? true : false}
            data-testid="gpt-input"
            label="Select a cell to ask a question!"
            multiline
            minRows={2}
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              editorContentRef.current = event.target.value;
            }}
          />
          <Button
            id="chat-completion-button"
            variant="contained"
            color="primary"
            onClick={fetchOpenAI}
            disabled={loading || selectedCell?.length === 0 ? true : false}
            size="small"
          >
            {loading ? <CircularProgress size={24} /> : "Get AI Response"}
          </Button>
        </div>
      </div>
    </TabPanel>
  );
};
