import React from "react";
import ReactMarkdown from "react-markdown";
import { theme as codeTheme } from "../CodeTab/outputTheme";
import Highlight, { defaultProps, Language } from "prism-react-renderer";
import styled from "styled-components";
import { DisclaimerContent } from "./DisclaimerContent";

// You can move these styled components into a separate file if you prefer
export const UserMessage = styled.div`
  background-color: #f0f8ff;
  border-radius: 16px;
  padding: 6px 10px;
  margin-bottom: 4px;
  box-shadow: -1px 0px 10px 1px rgba(0, 0, 0, 0.08);
  align-self: flex-end;
  margin-right: 10px;
  margin-left: auto;
  display: inline-block;
  width: auto;
  max-width: 80%;
  text-align: left;
  font-size: 1.2em;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export const AssistantMessage = styled.div`
  max-width: 95%;
  align-self: flex-start;
  margin-bottom: 8px;
`;

type ChatMessage = {
  role: "user" | "assistant";
  content: string;
  includedFiles?: { name: string; content: string }[];
  excludedFiles?: string[];
};

interface ChatHistoryProps {
  chatHistory: ChatMessage[];
}

export const ChatHistory: React.FC<ChatHistoryProps> = ({ chatHistory }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {chatHistory.map((message, index) => {
        const isUser = message.role === "user";
        const MessageComponent = isUser ? UserMessage : AssistantMessage;

        return (
          <div
            key={index}
            style={{
              marginBottom: "12px",
              textAlign: isUser ? "right" : "left",
            }}
          >
            <MessageComponent>
              <ReactMarkdown
                components={{
                  code(props) {
                    const { children, className, ...rest } = props;
                    const match = /language-(\w+)/.exec(className || "");
                    return match ? (
                      <div
                        style={{
                          overflowX: "auto",
                          width: "100%",
                          backgroundColor: "#f6f8fa",
                          margin: 4,
                          boxShadow: "-1px 0px 10px 1px rgba(0,0,0,0.08)",
                        }}
                      >
                        <Highlight
                          {...defaultProps}
                          code={String(children).replace(/\n$/, "")}
                          language={match[1] as Language}
                          theme={codeTheme}
                        >
                          {({
                            className: appliedName,
                            style,
                            tokens,
                            getLineProps,
                            getTokenProps,
                          }) => (
                            <pre
                              className={appliedName}
                              style={{
                                ...style,
                                padding: "16px",
                                width: "100%",
                              }}
                            >
                              {tokens.map((line, i) => (
                                <div
                                  {...getLineProps({ line, key: i })}
                                  key={i}
                                >
                                  {line.map((token, key) => (
                                    <span
                                      {...getTokenProps({ token, key })}
                                      key={key}
                                    />
                                  ))}
                                </div>
                              ))}
                            </pre>
                          )}
                        </Highlight>
                      </div>
                    ) : (
                      <code {...rest} className={className}>
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {message.content}
              </ReactMarkdown>
            </MessageComponent>

            {/* Show disclaimers if it's a user message */}
            {isUser && (
              <DisclaimerContent
                includedFiles={message.includedFiles}
                excludedFiles={message.excludedFiles}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
