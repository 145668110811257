import "./SnackBars.css";

import { forwardRef, useEffect, useRef, useState } from "react";
import { SnackbarContent, CustomContentProps } from "notistack";
import Switch from "@mui/material/Switch";
import { Card, CardActions, Typography } from "@mui/material";
import { theme } from "../../Themes/Themes";
import useStore from "../../Store/Store";
import { logger } from "../Logger";

interface SnackBarWithToggleProps extends CustomContentProps {}

const SnackBarWithToggle = forwardRef<HTMLDivElement, SnackBarWithToggleProps>(
  ({ id, ...props }, ref) => {
    const { isEditingSimulations } = useStore((state) => {
      return {
        isEditingSimulations: state.simulationsState.isEditingSimulations,
      };
    });
    const [checked, setChecked] = useState(false);
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    };

    useEffect(() => {
      const postToDrawio = async (hideIrrelevantCells: boolean) => {
        const drawioResponse = await useStore
          .getState()
          .postToDrawioWaitForResponse({
            action: "HIDE_IRRELEVANT_CELLS_TOGGLE_ACTION",
            data: { hideIrrelevantCells },
          });
        if (drawioResponse.status === "ERROR") {
          logger.error(
            "Error: HIDE_IRRELEVANT_CELLS_TOGGLE_ACTION",
            drawioResponse
          );
        }
      };

      postToDrawio(checked);

      return () => {
        postToDrawio(false);
      };
    }, [checked]);

    //If simulation in edit mode, the checked state should be false
    useEffect(() => {
      if (isEditingSimulations !== "") {
        setChecked(false);
      }
    }, [isEditingSimulations]);

    return (
      <SnackbarContent ref={ref} className="SnackBarWithToggle">
        <Card
          className="snackBarCard"
          style={{
            position: "relative",
            borderRadius: "30px",
            color: "#fffffffc",
            border: `1px solid ${theme.custom.pink}`,
            height: "30px",
            background: "transparent",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: "none",
          }}
        >
          <CardActions
            style={{
              position: "relative",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              style={{
                position: "relative",
                paddingLeft: "20px",
                height: "100%",
                verticalAlign: "middle",
                color: theme.custom.translucentPink,
              }}
            >
              {props.message}
            </Typography>
            <div style={{ position: "relative", left: "5px" }}>
              <Switch
                checked={checked}
                color="secondary"
                onChange={handleSwitchChange}
                data-testid="hide-cells-toggle"
              />
            </div>
          </CardActions>
        </Card>
      </SnackbarContent>
    );
  }
);

SnackBarWithToggle.displayName = "SnackBarWithToggle";

export default SnackBarWithToggle;
