import { Typography } from "@mui/material";
import { useContext } from "react";
import useStore from "../../../../Store/Store";
import { theme } from "../../../../Themes/Themes";
import { SimulationDescription } from "./SimulationDescription";
import SimulationsEditPanel from "./SimulationsEditPanel";
import {
  INITIAL_MODIFIED_SIMULATION_STATE,
  SimulationsContext,
} from "./SimulationsTab";
import { toggleCellsVisibility } from "./SimulationUtils";
import {
  discardRecordedActions,
  saveRecordedActions,
  startRecordingActions,
} from "../../../../Store/UndoManager";
import { resetTemporaryStyles } from "../../../DiagramHighlight/DiagramHighlight";

export const SimulationsBody: React.FC = () => {
  let {
    isRunning,
    simulationNameError,
    isSettingSimulationName,
    currentUnsavedSimulationObject,
    setSimulationNameError,
    setIsSettingSimulationName,
    setIsNewSimulation,
  } = useContext(SimulationsContext);
  const {
    simulations,
    setSimulations,
    isEditingSimulations,
    setIsEditingSimulations,
    selectedSimulationKey,
    setSelectedSimulationKey,
    setCurrentUnsavedSimulationObject,
    setSelectedCell,
  } = useStore((state) => ({
    simulations: state.simulationsState.simulations,
    setSimulations: state.setSimulations,
    isEditingSimulations: state.simulationsState.isEditingSimulations,
    setIsEditingSimulations: state.setIsEditingSimulations,
    selectedSimulationKey: state.simulationsState.selectedSimulationKey,
    setSelectedSimulationKey: state.setSelectedSimulationKey,
    setCurrentUnsavedSimulationObject: state.setCurrentUnsavedSimulationObject,
    setSelectedCell: state.setSelectedCell,
  }));

  const handleSimulationNameChange = async (e) => {
    await setCurrentUnsavedSimulationObject({
      ...currentUnsavedSimulationObject,
      name: e.target.value,
    });
    // check if name is already taken
    if (Object.keys(simulations).includes(e.target.value)) {
      setSimulationNameError("Name Already Taken!");
    } else {
      setSimulationNameError("");
    }
  };

  const handleKeyPress = async (e) => {
    if (currentUnsavedSimulationObject.name === "") {
      setSimulationNameError("Name cannot be empty!");
      return;
    }
    if (
      e.key === "Enter" &&
      !simulationNameError &&
      currentUnsavedSimulationObject.name !== selectedSimulationKey
    ) {
      const newSimulation: Simulation = {
        name: currentUnsavedSimulationObject.name,
        simSteps: [],
        simulationNodesAndEdges: {},
        description: "",
      };
      await startRecordingActions("SIMULATION_CREATED", {
        supressWarning: true,
      });
      await setCurrentUnsavedSimulationObject(newSimulation);
      await setSimulations({
        ...simulations,
        [currentUnsavedSimulationObject.name]: currentUnsavedSimulationObject,
      });
      setIsSettingSimulationName(false);
      await resetTemporaryStyles();
      await setSelectedSimulationKey(currentUnsavedSimulationObject.name);
      await setIsEditingSimulations("SimSteps");
      setIsNewSimulation(true);
      await saveRecordedActions("SIMULATION_CREATED");
      await startRecordingActions("SIMULATION_MODIFIED", {
        supressWarning: true,
      });
    }
  };

  const handleCreateSimulation = async () => {
    await toggleCellsVisibility(false);
    await setSelectedSimulationKey("");
    setIsSettingSimulationName(true);
    setIsNewSimulation(true);
    await setCurrentUnsavedSimulationObject(INITIAL_MODIFIED_SIMULATION_STATE);
    await discardRecordedActions("CURRENT_UNSAVED_SIMULATION_OBJECT");
  };

  return (
    <>
      {selectedSimulationKey && (
        <div
          className="simulationBody"
          style={{
            display: "flex",
          }}
        >
          {(!isEditingSimulations || isEditingSimulations === "SimSteps") && (
            <SimulationsEditPanel />
          )}
          {((!isEditingSimulations && !isRunning) ||
            isEditingSimulations === "Description") && (
            <SimulationDescription />
          )}
        </div>
      )}

      {!(
        isEditingSimulations === "SimSteps" ||
        (selectedSimulationKey && isRunning)
      ) && (
        <>
          {isSettingSimulationName ? (
            <div className="simulationBody">
              <input
                placeholder="Add New Simulation Name"
                onKeyPress={handleKeyPress}
                onChange={handleSimulationNameChange}
                value={currentUnsavedSimulationObject.name}
                className="simulationNameInput"
                autoFocus
              />
              {simulationNameError && (
                <Typography variant="subtitle2" color={theme.custom.pinkerPink}>
                  {simulationNameError}
                </Typography>
              )}
            </div>
          ) : (
            <div className="simulationBody">
              {!selectedSimulationKey && (
                <div className="bigGreyButton" onClick={handleCreateSimulation}>
                  <Typography variant="h6" fontWeight={"thin"}>
                    + Create Simulation
                  </Typography>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};
