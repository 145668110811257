import { EventEmitter } from "@hediet/std/events";
import {
  DrawioConfig,
  DrawioEvent,
  DrawioAction,
  DiagramChangedEvent,
  TelemetryDataPermissionRequest,
} from "./DrawioTypes";
import { BufferImpl } from "../utils/buffer";
import useStore from "../Store/Store";
import { prettify } from "./DrawioClientFactory";
import { extractTextFromHTML } from "../utils/MiscUtils";
import { v4 as uuidv4 } from "uuid";
import {
  newSelectedCellHandler,
  deleteCellsHandler,
  addCellsHandler,
  labelChangeHandler,
} from "../Store/StoreHandlers";
import {
  handleIsPerformingUndoRedo,
  handleSetStoreState,
} from "../Store/UndoManager";
import { handleSearchBarFocus } from "../Components/SearchBar/SearchBar";

/**
 * Represents a connection to an drawio iframe.
 */
export class DrawioClient<
  TCustomAction extends {} = never,
  TCustomEvent extends {} = never
> {
  private readonly onInitEmitter = new EventEmitter();
  public readonly onInit = this.onInitEmitter.asEvent();

  protected readonly onChangeEmitter = new EventEmitter<DrawioDocumentChange>();
  public readonly onChange = this.onChangeEmitter.asEvent();

  private readonly onSaveEmitter = new EventEmitter();
  public readonly onSave = this.onSaveEmitter.asEvent();

  private readonly onUnknownMessageEmitter = new EventEmitter<{
    message: TCustomEvent | DrawioEvent;
  }>();
  public readonly onUnknownMessage = this.onUnknownMessageEmitter.asEvent();

  // This is always up to date, except directly after calling load.
  private currentXml: string | undefined = undefined;

  private isMerging = false;

  constructor(
    private readonly messageStream: MessageStream,
    private readonly getConfig: () => Promise<DrawioConfig>
  ) {}

  private currentActionId = 0;
  private responseHandlers = new Map<
    string,
    { resolve: (response: DrawioEvent) => void; reject: () => void }
  >();

  protected sendCustomAction(action: TCustomAction): void {
    this.sendAction(action);
  }

  protected sendCustomActionExpectResponse(
    action: TCustomAction
  ): Promise<TCustomEvent> {
    return this.sendActionWaitForResponse(action);
  }

  private sendAction(action: DrawioAction | TCustomAction) {
    this.messageStream.sendMessage(JSON.stringify(action));
  }

  private sendActionWaitForResponse(action: DrawioAction): Promise<DrawioEvent>;
  private sendActionWaitForResponse(
    action: TCustomAction
  ): Promise<TCustomEvent>;
  private sendActionWaitForResponse(
    action: DrawioAction | TCustomAction
  ): Promise<DrawioEvent | TCustomEvent> {
    return new Promise((resolve, reject) => {
      const actionId = (this.currentActionId++).toString();

      this.responseHandlers.set(actionId, {
        resolve: (response) => {
          this.responseHandlers.delete(actionId);
          resolve(response);
        },
        reject,
      });

      this.messageStream.sendMessage(
        JSON.stringify(Object.assign(action, { actionId }))
      );
    });
  }
  private resolveResponseFromDrawio(drawioEvt) {
    const eventId = drawioEvt?.data?.eventId || drawioEvt?.eventId;
    if (eventId) {
      const responseHandler = this.responseHandlers.get(eventId);
      this.responseHandlers.delete(eventId);
      if (responseHandler) {
        responseHandler.resolve(drawioEvt);
      }
    }
  }

  protected async handleEvent(evt: { event: string }): Promise<void> {
    const drawioEvt = evt as DrawioEvent;
    // console.log(
    //   "%cdrawio -> CodeCanvas: " + prettify(drawioEvt),
    //   "color: blue;"
    // );
    if (drawioEvt.event === "setDatabaseItem") {
      const eventDetails = drawioEvt.data;
      const graphXml = eventDetails?.data;
      if (eventDetails && graphXml && graphXml !== this.currentXml) {
        this.currentXml = graphXml;
        const setDrawioXML = useStore.getState().setDrawioXML;
        setDrawioXML(this.currentXml);
      }
    } else if (drawioEvt.event === "UPDATE_DIAGRAM_RESPONSE") {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (
      drawioEvt.event === "OPEN_DIAGRAM_FROM_BROWSER_STORAGE_RESPONSE"
    ) {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (drawioEvt.event === "BROWSER_STORAGE_OPERATION_RESPONSE") {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (drawioEvt.event === "TOGGLE_SHAPES_PANEL_RESPONSE") {
      this.handleOpenFormatPanelResponse(drawioEvt);
    } else if (drawioEvt.event === "UPDATE_SHADOW_DATA_RESPONSE") {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (drawioEvt.event === "HIDE_IRRELEVANT_CELLS_TOGGLE_ACTION") {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (drawioEvt.event === "CLOSE_FORMAT_PANEL") {
      useStore.getState().setToolbar({ showEditButton: true });
    } else if (drawioEvt.event === "RESET_CACHE_RESPONSE") {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (drawioEvt.event === "CHANGE_CELLS_STYLE_RESPONSE") {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (drawioEvt.event === "CODECANVAS_STATE_CHANGE_RESPONSE") {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (drawioEvt.event === "CODECANVAS_RECORD_STATE_CHANGE_RESPONSE") {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (drawioEvt.event === "CURRENT_PATH_CHANGE_RESPONSE") {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (drawioEvt.event === "NEW_LINKED_CELL_RESPONSE") {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (drawioEvt.event === "KEYDOWN_RESPONSE") {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (drawioEvt.event === "UNDO_MANAGER_OPERATION_RESPONSE") {
      this.resolveResponseFromDrawio(drawioEvt);
    } else if (drawioEvt.event === "init") {
      this.onInitEmitter.emit();
    } else if (drawioEvt.event === "onSelectionChange") {
      newSelectedCellHandler(drawioEvt.selectedCellIds);
    } else if (drawioEvt.event === "cellsRemoved") {
      deleteCellsHandler(drawioEvt.cellsRemoved);
    } else if (drawioEvt.event === "cellsAdded") {
      addCellsHandler(drawioEvt.cellsAdded);
    } else if (drawioEvt.event === "labelChanged") {
      labelChangeHandler(drawioEvt.newName, drawioEvt.cellId);
    } else if (drawioEvt.event === "autosave") {
      const oldXml = this.currentXml;
      if (oldXml !== drawioEvt.xml) {
        this.currentXml = drawioEvt.xml;

        // Don't emit a change event if we're merging some changes in.
        if (!this.isMerging) {
          this.onChangeEmitter.emit({
            newXml: this.currentXml,
            oldXml,
          });
        }
      }
    } else if (drawioEvt.event === "save") {
      const oldXml = this.currentXml;
      this.currentXml = drawioEvt.xml;
      if (oldXml !== this.currentXml) {
        // a little bit hacky.
        // If "save" does trigger a change,
        // treat save as autosave and don't actually save the file.
        this.onChangeEmitter.emit({ newXml: this.currentXml, oldXml });
      } else {
        // Otherwise, the change has already
        // been reported by autosave.
        this.onSaveEmitter.emit();
      }
    } else if (drawioEvt.event === "export") {
      // sometimes, message is not included :(
      // this is a hack to find the request to resolve
      const vals = [...this.responseHandlers.values()];
      this.responseHandlers.clear();
      if (vals.length !== 1) {
        for (const val of vals) {
          val.reject();
        }
      } else {
        vals[0].resolve(drawioEvt);
      }
    } else if (drawioEvt.event === "configure") {
      const config = await this.getConfig();
      this.sendAction({
        action: "configure",
        config,
      });
    } else if (drawioEvt.event === "INIT_COMPLETED") {
      useStore.getState().setDrawioInitCompleted(true);
    } else if (drawioEvt.event === "LANDING_PAGE_LOADED") {
      useStore.getState().setLandingPageLoaded(true);
    } else if (drawioEvt.event === "DIAGRAM_CHANGED") {
      this.handleDiagramChanged(drawioEvt);
    } else if (drawioEvt.event === "TELEMETRY_DATA_PERMISSION_REQUEST") {
      this.handleTelemetryDataPermissionRequest(drawioEvt);
    } else if (drawioEvt.event === "ADD_AS_SIMULATION_STEP") {
      this.handleAddAsSimulationStep(drawioEvt);
    } else if (drawioEvt.event === "DELETE_WARNING") {
      this.handleDeleteWarning(drawioEvt);
    } else if (drawioEvt.event === "LINK_CELL_TO_CODE") {
      await this.handleLinkCellToCode(drawioEvt);
    } else if (drawioEvt.event === "SET_STORE_STATE") {
      handleSetStoreState(drawioEvt);
    } else if (drawioEvt.event === "PERFORMING_UNDO_REDO") {
      handleIsPerformingUndoRedo(drawioEvt);
    } else if (drawioEvt.event === "SEARCH_BAR_FOCUS") {
      handleSearchBarFocus(drawioEvt);
    } else if (drawioEvt.event === "ACTION_STATE_CHANGED") {
      this.handleActionStateChange(drawioEvt);
    } else {
      this.onUnknownMessageEmitter.emit({ message: drawioEvt });
    }

    // used to avoid infinit loops like sell selection flickering
    if (drawioEvt.event && typeof drawioEvt.event === "string") {
      useStore.getState().setlastDrawioEventName(drawioEvt.event);
    }
  }

  public handleOpenFormatPanelResponse(drawioEvt) {
    if (!drawioEvt.eventId && drawioEvt?.data?.state) {
      useStore.getState().setToolbar({
        showEditButton: drawioEvt?.data?.state === "minimized",
      });
    } else {
      this.resolveResponseFromDrawio(drawioEvt);
    }
  }

  public async mergeXmlLike(xmlLike: string): Promise<void> {
    const promise = this.sendActionWaitForResponse({
      action: "merge",
      xml: xmlLike,
    });
    this.isMerging = true;
    try {
      const evt = await promise;
      if (evt.event !== "merge") {
        throw new Error("Invalid response");
      }
      if (evt.error) {
        throw new Error(evt.error);
      }
    } finally {
      this.isMerging = false;
    }
  }

  /**
   * This loads an xml or svg+xml Draw.io diagram.
   */
  public async loadXmlLike(
    xmlLike: string,
    fileURL: string = null
  ): Promise<void> {
    this.currentXml = undefined;
    if (!fileURL) {
      this.sendAction({
        action: "load",
        xml: xmlLike,
        autosave: 1,
      });
    } else {
      this.sendAction({
        action: "load",
        xml: xmlLike,
        autosave: 1,
        fileURL: fileURL,
      });
    }

    // We request the xml to detect if an autosave is a real change.
    await this.getXml();
  }

  public async loadPngWithEmbeddedXml(png: Uint8Array): Promise<void> {
    let str = BufferImpl.from(png).toString("base64");
    this.loadXmlLike("data:image/png;base64," + str, "");
  }

  public async export(extension: string): Promise<BufferImpl> {
    if (extension.endsWith(".png")) {
      return await this.exportAsPngWithEmbeddedXml();
    } else if (extension.endsWith(".drawio") || extension.endsWith(".dio")) {
      const xml = await this.getXml();
      return BufferImpl.from(xml, "utf-8");
    } else if (extension.endsWith(".svg")) {
      return await this.exportAsSvgWithEmbeddedXml();
    } else {
      throw new Error(
        `Invalid file extension "${extension}"! Only ".png", ".svg" and ".drawio" are supported.`
      );
    }
  }

  public handleAddAsSimulationStep(drawioEvt) {
    const isEditingSimulations =
      useStore.getState().simulationsState.isEditingSimulations;
    if (!isEditingSimulations) {
      const dialogMessage = `Please open a simulation and click "Edit" then try again.`;
      useStore.getState().setDialog("WARNING_DIALOG", {
        message: dialogMessage,
      });
      return;
    }
    let data = drawioEvt?.data;
    if (data) {
      data = data[0]; // work around until handling multiSelect is implemented
      let nodeSimStep: SimStep = {
        simStepId: uuidv4(),
        diagramNodeId: data?.cellId,
        simStepLabel: extractTextFromHTML(data?.label),
        simStepDescription: "",
        isEdge: data?.isEdge,
      };

      // if data.incomingEdgesWithSourceCells is not empty, then check if the source cell is the last simStep in the current simulation
      // if so, then add the edge to the simulation first and then add the new simStep
      let currentUnsavedSimulationObject =
        useStore.getState().currentUnsavedSimulationObject;

      if (
        data?.incomingEdgesWithSourceCells?.length > 0 &&
        currentUnsavedSimulationObject?.simSteps?.length > 0
      ) {
        let lastSimStep =
          currentUnsavedSimulationObject.simSteps[
            currentUnsavedSimulationObject.simSteps.length - 1
          ];
        let lastSimStepDiagramNodeId = lastSimStep.diagramNodeId;
        // find the arrow that connects the last simStep to the new simStep
        let incomingEdgeWithSourceCell = data.incomingEdgesWithSourceCells.find(
          (edge) => edge?.sourceCellId === lastSimStepDiagramNodeId
        );
        if (incomingEdgeWithSourceCell) {
          // add the edge to the simulation
          let edgeSimStep: SimStep = {
            simStepId: uuidv4(),
            diagramNodeId: incomingEdgeWithSourceCell?.edgeCellId,
            simStepLabel: extractTextFromHTML(
              incomingEdgeWithSourceCell?.edgeLabel
            ),
            simStepDescription: "",
            isEdge: 1,
          };
          this.addSimStep(edgeSimStep);
        }
      }
      this.addSimStep(nodeSimStep);

      useStore.getState().postToDrawio({
        drawioRequestId: drawioEvt.drawioRequestId,
        action: "ADD_AS_SIMULATION_STEP_RESPONSE",
        status: "SUCCESS",
      });
    }
  }

  public async addSimStep(simStep: SimStep) {
    let currentUnsavedSimulationObject =
      useStore.getState().currentUnsavedSimulationObject;

    let simulationDiagramElementId = simStep.diagramNodeId;

    let simulationDiagramElement: SimulationDiagramElement = {
      simStepIds: [simStep.simStepId],
    };
    useStore.getState().setFlag("isAddingNewSimStep", true);
    // check if the diagram element is already in the simulation
    Object.entries(
      currentUnsavedSimulationObject.simulationNodesAndEdges
    ).forEach(([key, value]) => {
      if (key === simulationDiagramElementId) {
        // if diagram element already exists, add this simStep id to the simStepIds array
        simulationDiagramElement = {
          ...value,
          simStepIds: [...value.simStepIds, simStep.simStepId],
        };
      }
    });

    let simSteps = currentUnsavedSimulationObject.simSteps;
    await useStore.getState().setCurrentUnsavedSimulationObject({
      ...currentUnsavedSimulationObject,
      simSteps: [...simSteps, simStep],
      simulationNodesAndEdges: {
        ...currentUnsavedSimulationObject.simulationNodesAndEdges,
        [simulationDiagramElementId]: simulationDiagramElement,
      },
    });
    await useStore.getState().setSimulationsState({
      ...useStore.getState().simulationsState,
      currentStep: simStep.simStepId,
    });

    await useStore.getState().setFlag("isAddingNewSimStep", false);
  }

  public handleActionStateChange(drawioEvt) {
    const { actionName, newState } = drawioEvt?.data;

    if (actionName === "undo" || actionName === "redo") {
      useStore.getState().setUndoRedoState({
        ...useStore.getState().undoRedoState,
        [actionName]: newState,
      });
    }

    useStore.getState().postToDrawio({
      drawioRequestId: drawioEvt.drawioRequestId,
      action: "ACTION_STATE_CHANGED_RESPONSE",
      status: "SUCCESS",
    });
  }

  public handleDeleteWarning(drawioEvt) {
    const state = useStore.getState();
    let repoData = state.repoData;
    let cellToPath = state.cellToPath;
    let simulations = state.simulationsState.simulations;
    let isLinkedToSimulation = false;
    let wikiDeletionWarningFlag = false;
    let isLinkedToFile = false;
    let wikiCells = [];
    let simulationCells = {};
    let cellsToDelete = drawioEvt.data;
    let message = "";
    cellsToDelete.forEach((id) => {
      let data = repoData[id] || repoData[cellToPath[id]];
      if (data?.wiki) {
        if (
          repoData[data.parentPath]?.type === "blob" ||
          repoData[data.parentPath]?.type === "tree" ||
          repoData[data.path]?.type === "blob" ||
          repoData[data.path]?.type === "tree"
        ) {
          isLinkedToFile = true;
        }
        wikiDeletionWarningFlag = true;
        let cellIdentifier = data?.cellName || id;
        wikiCells.push(`"${cellIdentifier}"`);
      }
      if (Object.values(simulations).length > 0) {
        Object.values(simulations).forEach((simulation) => {
          if (simulation.simulationNodesAndEdges[id]) {
            isLinkedToSimulation = true;
            let cellIdentifier = data?.cellName || id;
            if (!simulationCells[simulation.name]) {
              simulationCells[simulation.name] = [];
            }
            simulationCells[simulation.name].push(`"${cellIdentifier}"`);
          }
        });
      }
      // Check in currentUnsavedSimulationObject too in case the user is editing a simulation, added an unsaved cell, linked it to a simStep, and then tries to delete it
      if (state.currentUnsavedSimulationObject) {
        let currentUnsavedSimulationObject =
          state.currentUnsavedSimulationObject;
        if (currentUnsavedSimulationObject.simulationNodesAndEdges[id]) {
          isLinkedToSimulation = true;
          let cellIdentifier = data?.cellName || id;
          if (!simulationCells[currentUnsavedSimulationObject.name]) {
            simulationCells[currentUnsavedSimulationObject.name] = [];
          }
          simulationCells[currentUnsavedSimulationObject.name].push(
            `"${cellIdentifier}"`
          );
        }
      }
    });

    if (wikiDeletionWarningFlag) {
      message += `The following cells have wiki data saved: ${wikiCells.join(
        ", "
      )}. \nA cell not linked to code can not save its wiki ${isLinkedToSimulation} if deleted. Deleting parents will delete children. Are you sure you want to proceed 
      with this deletion?\n`;
    }

    if (isLinkedToSimulation) {
      let linkedToSimulationMessage = `The following cells are linked to a simulation:\n ${Object.entries(
        simulationCells
      )?.map(([key, value]) => {
        return `\n- Simulation Name: "${key}" is linked to\n
               Cell Names: ${(value as string[]).join(", ")}`;
      })}. \n
      \nDeleting a cell will keep its simStep in the simulation, but it will no longer be linked to the cell. Are you sure you want to proceed with this deletion?\n`;
      state.setDialog("WARNING_DIALOG", {
        message: linkedToSimulationMessage,
        button1Function: async (wikiDeletionWarningFlag) => {
          // Remove from simulationNodesAndEdges
          // TODO: All the spreading here can be removed with Thunk library
          let simulations = { ...state.simulationsState.simulations };
          Object.values(simulations).forEach((simulation) => {
            let newSimulation = { ...simulation };
            let simulationNodesAndEdges = {
              ...newSimulation.simulationNodesAndEdges,
            };
            Object.keys(simulationNodesAndEdges).forEach((key) => {
              if (cellsToDelete.includes(key)) {
                delete simulationNodesAndEdges[key];
              }
            });
            // remove from simSteps
            let simSteps = [...newSimulation.simSteps];
            simSteps = simSteps.map((simStep) => {
              let newSimStep = { ...simStep };
              if (cellsToDelete.includes(newSimStep?.diagramNodeId)) {
                newSimStep.diagramNodeId = null;
              }
              return newSimStep;
            });
            newSimulation.simSteps = simSteps;
            newSimulation.simulationNodesAndEdges = simulationNodesAndEdges;
            simulations[simulation.name] = newSimulation;
          });
          await state.setSimulationsState({
            ...state.simulationsState,
            simulations: simulations,
          });
          // Remove from simulationNodesAndEdges of currentUnsavedSimulationObject
          let currentUnsavedSimulationObject = {
            ...state.currentUnsavedSimulationObject,
          };
          let newSimulationNodesAndEdges = {
            ...currentUnsavedSimulationObject.simulationNodesAndEdges,
          };

          Object.keys(newSimulationNodesAndEdges).forEach((key) => {
            if (cellsToDelete.includes(key)) {
              delete newSimulationNodesAndEdges[key];
            }
          });
          currentUnsavedSimulationObject.simulationNodesAndEdges =
            newSimulationNodesAndEdges;

          // Remove diagramNodeId from simSteps of currentUnsavedSimulationObject
          let simSteps = [...currentUnsavedSimulationObject.simSteps];
          simSteps = simSteps.map((simStep) => {
            let newSimStep = { ...simStep };
            if (cellsToDelete.includes(newSimStep?.diagramNodeId)) {
              newSimStep.diagramNodeId = null;
            }
            return newSimStep;
          });
          await state.setCurrentUnsavedSimulationObject({
            ...currentUnsavedSimulationObject,
            simSteps: simSteps,
          });

          isLinkedToSimulation = false;

          if (!wikiDeletionWarningFlag) {
            useStore.getState().postToDrawio({
              drawioRequestId: drawioEvt.drawioRequestId,
              action: "CONTINUE_DELETE",
              data: "Continue",
            });
          }
        },
      });
    }

    if (wikiDeletionWarningFlag) {
      state.setDialog("DELETE_WARNING", {
        message: message,
        data: {
          drawioRequestId: drawioEvt.drawioRequestId,
          isLinkedToFile: isLinkedToFile,
        },
      });
    } else if (!isLinkedToSimulation) {
      useStore.getState().postToDrawio({
        drawioRequestId: drawioEvt.drawioRequestId,
        action: "CONTINUE_DELETE",
        data: "Continue",
      });
    }
  }

  public async handleLinkCellToCode(drawioEvt) {
    // set flag that stops change of selectedCell when clicking items in source doc
    // flag should be unset anytime change of selected Cell happens from drawio Side
    const state = useStore.getState();

    if (state.multiSelect) {
      state.setErrorNotification(
        "Cannot link cell if multiple cells are selected!"
      );
      return;
    }
    state.setPausePathChangeToDrawio(true);
  }

  private async getXmlUncached(): Promise<string> {
    const response = await this.sendActionWaitForResponse({
      action: "export",
      format: "xml",
    });
    if (response.event !== "export") {
      throw new Error("Unexpected response");
    }
    return response.xml;
  }

  public async getXml(): Promise<string> {
    if (!this.currentXml) {
      const xml = await this.getXmlUncached();
      if (!this.currentXml) {
        // It might have been changed in the meantime.
        // Always trust autosave.
        this.currentXml = xml;
      }
    }
    return this.currentXml;
  }

  public async exportAsPngWithEmbeddedXml(): Promise<BufferImpl> {
    const response = await this.sendActionWaitForResponse({
      action: "export",
      format: "xmlpng",
    });
    if (response.event !== "export") {
      throw new Error("Unexpected response");
    }
    const start = "data:image/png;base64,";
    if (!response.data.startsWith(start)) {
      throw new Error("Invalid data");
    }
    const base64Data = response.data.substr(start.length);
    return BufferImpl.from(base64Data, "base64");
  }

  public async exportAsSvgWithEmbeddedXml(): Promise<BufferImpl> {
    const response = await this.sendActionWaitForResponse({
      action: "export",
      format: "xmlsvg",
    });
    if (response.event !== "export") {
      throw new Error("Unexpected response");
    }
    const start = "data:image/svg+xml;base64,";
    if (!response.data.startsWith(start)) {
      throw new Error("Invalid data");
    }
    const base64Data = response.data.substr(start.length);
    return BufferImpl.from(base64Data, "base64");
  }

  public triggerOnSave(): void {
    this.onSaveEmitter.emit();
  }

  private handleDiagramChanged(drawioEvt: DiagramChangedEvent) {
    try {
      const diff = drawioEvt?.data?.diff;
      if (diff) {
        if (Object.keys(diff).length > 0) {
          useStore.getState().setDiagramModified(true);
        } else {
          useStore.getState().setDiagramModified(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
  private handleTelemetryDataPermissionRequest(
    drawioEvt: TelemetryDataPermissionRequest
  ) {
    const telemetryData =
      window.localStorage.getItem("telemetryData") === "false"
        ? "DENIED"
        : "ALLOWED";
    useStore.getState().postToDrawio({
      drawioRequestId: drawioEvt.drawioRequestId,
      action: "TELEMETRY_DATA_PERMISSION_REQUEST_RESPONSE",
      status: "SUCCESS",
      data: {
        telemetryData: telemetryData,
      },
    });
  }
}
export interface DrawioDocumentChange {
  oldXml: string | undefined;
  newXml: string;
}

export interface MessageStream {
  registerMessageHandler(handler: (message: unknown) => void): void;
  sendMessage(message: unknown): void;
}
