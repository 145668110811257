import EditButtonSVG from "../../Media/EditButton";
import styled from "styled-components";
import useStore from "../../Store/Store";
import { useEffect, useState } from "react";

const EditButtonWrapper = styled.div`
  position: fixed;
  top: 43vh;
  left: -0.7vw;
  height: 5vw;
  width: 5vw;
  cursor: pointer;
`;

const EditButton: React.FC<any> = () => {
  const { postToDrawio } = useStore((state) => ({
    postToDrawio: state.postToDrawio,
  }));

  const [showPanel, setShowPanel] = useState(false);

  const handleClick = async () => {
    setShowPanel(!showPanel);
  };

  useEffect(() => {
    postToDrawio({
      action: "TOGGLE_SHAPES_PANEL",
      data: { show: showPanel },
    });
  }, [postToDrawio, showPanel])

  return (
    <EditButtonWrapper onClick={handleClick}>
      <EditButtonSVG />
    </EditButtonWrapper>
  );
};

export default EditButton;
